import { Box } from "@mui/material";
import React, { useRef, useEffect } from "react";

const BoxClickOut = ({
  onClickOut,
  children,
  att,
  className
}) => {
  const mainRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mainRef.current && !mainRef.current.contains(event.target)) {
        onClickOut();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <Box ref={mainRef} sx={{ ...att }} className={className}>
      {children}
    </Box>
  );
};

export default BoxClickOut;
