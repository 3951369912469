import { ReactNode, Suspense, useEffect } from "react";
import { LoadingProvider } from "./loading";
// import NotifyProvider from "./notify";
import { CommonDataProvider } from "./common-data";
import { ChatList, MobileChatDetail, ScrollToTop } from "components";
import NotifyProvider from "./notify";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import useTwilio from "hooks/useTwilio";
import { SEARCH_PARAMS } from "constants/routes";

const AppProvider = ({ children }: { children: ReactNode }) => {
  const { downMobileBreakpoint: isMobile } = useMediaBreakpoints();

  useTwilio();

  const getUtmSource = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmSource = searchParams.get(SEARCH_PARAMS.utmSource);
    const localUtmSource = localStorage.getItem(SEARCH_PARAMS.utmSource);
    if (!localUtmSource) {
      localStorage.setItem(SEARCH_PARAMS.utmSource, utmSource || "origin");
    }
  };

  useEffect(() => {
    getUtmSource();
  }, []);

  return (
    <CommonDataProvider>
      <LoadingProvider>
        <ScrollToTop />
        <NotifyProvider>
          {children}

          <Suspense fallback={<div />}>
            {isMobile ? <MobileChatDetail /> : <ChatList />}
          </Suspense>
        </NotifyProvider>
      </LoadingProvider>
    </CommonDataProvider>
  );
};

export default AppProvider;
