import {
  Box,
  Typography,
  Grid,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomButton, Loading } from "..";
import CheckIcon from "@mui/icons-material/Check";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import CloseIcon from "@mui/icons-material/Close";

// import MultipleSelect from "pages/business-domain/OrderBar/components/MultipleSelect";
import useStyles from "./styles";
import { APITemplate } from "services/api";
import { checkIsEatInOrder } from "services/utils/orderHelpers";

const OrderFilter = (props) => {
  const classes = useStyles();

  const {
    orderFilters,
    setOrderFilters,
    setShowDot,
    dateRange,
    setDateRange,
    closeSelectAttPopup,
    chosedFilter,
    setChosedFilter,
  } = props;

  const [tableNameList, setTableNameList] = React.useState([]);
  const [filterConfig, setFilterConfig] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    APITemplate(
      "freshdi.data.app.homescreen.get_config_v2",
      null,
      (response) => {
        setLoading(false);
        setFilterConfig(response?.data?.message);
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    APITemplate(
      "freshdi.data.subfarm_table.get_tables",
      null,
      (response) => {
        setTableList(response?.data?.message);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setTableNameList(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeFilter = (event) => {
    if (Object.keys(removeNullKeys(chosedFilter)).length === 0) {
      setShowDot(false);
    } else {
      setShowDot(true);
    }
    setOrderFilters(
      reformatFilterData(orderFilters, chosedFilter, tableNameList, dateRange)
    );
    closeSelectAttPopup();
  };

  return (
    <Box className={classes.background}>
      {/* <BoxClickOut
        att={{ width: "100%" }}
        onClickOut={closeSelectAttPopup}
        children={
        }
      /> */}
      <Box className={classes.main}>
        <Box display="flex" justifyContent="space-between" p={2}>
          <IconButton onClick={closeSelectAttPopup}>
            <CloseIcon />
          </IconButton>
          <Typography className={classes.filterTopText}>Bộ lọc</Typography>
          <Button
            onClick={() => {
              setChosedFilter({
                order_status: null,
                order_method: null,
                creation: "Hôm nay",
                payment_method: null,
              });
              setShowDot(false);
            }}
          >
            <Typography className={classes.filterTopText}>Mặc định</Typography>
          </Button>
        </Box>
        <Divider />
        {loading && <Loading circle showText={false} att={{ mt: 2 }} />}
        {!loading &&
          filterConfig
            ?.filter((filter) => filter?.alias !== "order_status")
            .map((filter, index) => (
              <Box key={index} px={2}>
                <Typography m={1} fontWeight="bold">
                  {filter.name}
                </Typography>
                <Grid container>
                  {filter.data.map((option, index) => {
                    let borderSetting = "1.5px solid #E0E0E0";
                    let showIcon = false;

                    if (chosedFilter[filter.alias] === option) {
                      borderSetting = "1.5px solid black";
                      showIcon = true;
                    }
                    return (
                      <Grid item key={index} xs={3}>
                        <Box
                          m={1}
                          border={borderSetting}
                          className={classes.filterButton}
                          onClick={() => {
                            if (chosedFilter[filter.alias] === option) {
                              setChosedFilter({
                                ...chosedFilter,
                                [filter.alias]: null,
                              });
                            } else {
                              setChosedFilter({
                                ...chosedFilter,
                                [filter.alias]: option,
                              });
                            }
                          }}
                        >
                          <Typography variant="body2" m={1}>
                            {option}
                          </Typography>
                          {showIcon && <CheckIcon sx={{ fontSize: 14 }} />}
                        </Box>
                      </Grid>
                    );
                  })}
                  {checkIsEatInOrder(chosedFilter.order_method, true) &&
                    filter.alias === "order_method" && (
                      <Grid item key={index} xs={6}>
                        <Box m={1}>
                          {/* <MultipleSelect
                            optionList={tableList}
                            label="Khu vực/bàn"
                            fullWidth={true}
                            handleChange={handleChange}
                            tableList={tableNameList}
                          /> */}
                        </Box>
                      </Grid>
                    )}
                  {chosedFilter.creation === "Tùy chọn" &&
                    filter.alias === "creation" && (
                      <DateRange
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                        onChange={(item) => setDateRange([item.selection])}
                      />
                    )}
                </Grid>
              </Box>
            ))}
        <Box p={2}>
          <CustomButton
            text="Đã xong"
            fullWidth={true}
            event={handleChangeFilter}
          />
        </Box>
      </Box>
    </Box>
  );
};

const removeNullKeys = (object) => {
  let newObj = structuredClone(object);

  Object.keys(newObj).forEach((key) => {
    if (newObj[key] == null) {
      delete newObj[key];
    }
  });
  return newObj;
};

const reformatFilterData = (
  orderFilters,
  chosedFilter,
  tableList = [],
  dateRange
) => {
  let tmp = structuredClone(chosedFilter);
  if ("order_method" in tmp) {
    console.log(tmp.order_method);
    if (tmp.order_method !== null) {
      tmp.order_method = {
        name: tmp.order_method,
        value: tableList,
      };
    } else {
      delete tmp.order_method;
    }
  }
  if (tmp.creation === "Hôm nay") {
    let today = new Date();

    tmp.creation = {
      start_date: formatDate(today),
      end_date: formatDate(today),
    };
  }
  if (tmp.creation === "Tùy chọn") {
    tmp.creation = {
      start_date: formatDate(dateRange[0].startDate),
      end_date: formatDate(dateRange[0].endDate),
    };
  }
  let result = orderFilters;
  result.order_status = tmp.order_status;
  result.creation = tmp.creation;
  result.order_method = tmp.order_method;
  result.payment_method = tmp.payment_method;
  result = removeNullKeys(result);
  return result;
};

export const formatDate = (d) => {
  if (d === null) {
    return null;
  }
  let dd = String(d.getDate()).padStart(2, "0");
  let mm = String(d.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = d.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

export default OrderFilter;
