import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  horList: {
    display: 'flex',
    overflow: 'hidden',
    overflowX: 'scroll',
    '-ms-overflow-style': 'none',
    'scrollbarWidth': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  }
}))