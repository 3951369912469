import { SVGProps } from "react";

export function ChatIcon({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CircleChatIcon({
  width = 36,
  height = 30,
  viewBox = "0 0 36 30",
  stroke = "#FCFCFD",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <g clip-path="url(#clip0_8691_204194)">
        <path
          d="M18.5 29C25.4036 29 31 23.4036 31 16.5C31 9.59644 25.4036 4 18.5 4C11.5964 4 6 9.59644 6 16.5C6 23.4036 11.5964 29 18.5 29Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M24.125 18.375C24.125 18.7065 23.9933 19.0245 23.7589 19.2589C23.5245 19.4933 23.2065 19.625 22.875 19.625H15.375L12.875 22.125V12.125C12.875 11.7935 13.0067 11.4755 13.2411 11.2411C13.4755 11.0067 13.7935 10.875 14.125 10.875H22.875C23.2065 10.875 23.5245 11.0067 23.7589 11.2411C23.9933 11.4755 24.125 11.7935 24.125 12.125V18.375Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_8691_204194">
          <rect
            width="27"
            height="27"
            fill="white"
            transform="translate(5 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
