import { Autorenew, Close, Done } from "@mui/icons-material";
import { Box, Fab } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";

import useStyles from "./styles";

const TakePhoto = (props) => {
  const videoRef = useRef();
  const photoRef = useRef();
  const classes = useStyles();
  const { onClose, setImage } = props;

  const [showPreview, setShowPreview] = useState(false);
  const [cameraMode, setCameraMode] = useState("user");

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: 300,
          facingMode: cameraMode,
        },
      })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };

  const getImage = () => {
    let video = videoRef?.current;
    let photo = photoRef?.current;
    let width = video?.clientWidth;
    let height = video?.clientHeight;
    photo.width = width;
    photo.height = height;
    let ctx = photo.getContext("2d");
    ctx?.drawImage(video, 0, 0, width, height);
    setShowPreview(true);
  };

  const turnOffCamera = async () => {
    videoRef.current.srcObject.getTracks()[0].stop()
  };

  const handleClickClose = () => {
    if (showPreview) {
      setShowPreview(false);
    } else {
      onClose();
    }
    turnOffCamera();
  };

  const handleClickDone = () => {
    if (showPreview) {
      setImage(photoRef?.current?.toDataURL());
    } else {
      setCameraMode(cameraMode === "user" ? "environment" : "user");
    }
    turnOffCamera();
  };

  useEffect(() => {
    if (!showPreview) {
      getVideo();
    }
  }, [videoRef, showPreview]);

  return (
    <Box className={classes.main}>
      <canvas
        ref={photoRef}
        style={{ width: showPreview ? videoRef?.current?.clientWidth : 0 }}
      />
      <video
        ref={videoRef}
        autoPlay
        className={classes.video}
        style={{ width: showPreview ? 0 : "100%" }}
      />

      <Box p={6} className={classes.bottomBg}>
        <Fab className="close" onClick={handleClickClose}>
          <Close />
        </Fab>
        {!showPreview && <Fab className="takePhoto" onClick={getImage} />}
        <Fab className="close" onClick={handleClickDone}>
          {showPreview ? <Done /> : <Autorenew />}
        </Fab>
      </Box>
    </Box>
  );
};

export default TakePhoto;
