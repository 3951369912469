
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  img: {
    height: 450,
    width: '100%',
    cursor: 'grab',
    backgroundSize: '50px',
    transformOrigin: 'center center',
    objectFit: 'cover'
  },
  background: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'rgba(0,0,0,0.8)',
    zIndex: 1201,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    flex: '1 1',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    maxWidth: 850,
    maxHeight: '100%',
    '& button': {
      backgroundColor: '#fff',
      color: '#333333',
      position: 'absolute',
      maxHeight: 30,
      '&:after': {
        display: 'none'
      },
      '&:hover': {
        backgroundColor: '#fff'
      },
      '& svg': {
        fontSize: 12
      }
    },
    '& .leftBtn': {
      left: 16
    },
    '& .rightBtn': {
      right: 16
    }
  },
  dots: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'absolute',
    bottom: 32
  },
  dot: {
    borderRadius: '50%',
    cursor: 'pointer'
  },
}))