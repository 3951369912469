import React from "react";
import { Box, Typography } from "@mui/material";

import useStyles from "./styles";
import { validateCommon } from "services/utils/helpers";

const ItemPrice = ({ item, hasDollar = false, att }) => {
  const classes = useStyles();

  return (
    <Box className={classes.priceBox} sx={{ ...att }}>
      <Typography className="itemPrice">
        {item?.price_discount && item?.price_discount !== 0
          ? `${validateCommon("money", item?.price_discount)}`
          : validateCommon("money", item?.price)}
        {hasDollar && " đ"}
      </Typography>
      <Typography className="originPrice">
        {item && item?.price_discount && item?.price_discount !== 0
          ? `${validateCommon("money", item?.price)} ${hasDollar ? "đ" : ""}`
          : ""}
      </Typography>
    </Box>
  );
};

export default ItemPrice;
