import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  video: {
    maxWidth: 550,
    maxHeight: "100%",
  },
  bottomBg: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    "& .close": {
      width: 40,
      height: 40,
      minHeight: 40,
      backgroundColor: "#ddd",
    },
    "& .takePhoto": {},
  },
  main: {
    textAlign: "center",
    backgroundColor: "#000",
    "& canvas, video": {
      transition: "width 0.1s linear",
    },
  },
}));
