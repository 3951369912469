import React, { useRef } from "react";
import { MenuItem, TextField } from "@mui/material";
import useStyles from "./styles";

const allowClickTypes = ["datetime-local", "date"];

export default function RoundInputWithLabel(props) {
  const { menuItems } = props;

  const classes = useStyles();
  const inputRef = useRef();
  const handleClickInput = (e) => {
    if (allowClickTypes.indexOf(props?.type) > -1) {
      inputRef?.current?.showPicker();
    }
  };

  return (
    <TextField
      autoComplete="off"
      className={classes.textField}
      fullWidth
      onClick={handleClickInput}
      variant={props?.variant || "outlined"}
      inputRef={inputRef}
      size={props?.size || "normal"}
      InputLabelProps={{ shrink: true }}
      sx={{
        ...props?.att,
        "& fieldset": { top: props?.variant ? 0 : -5 },
      }}
      SelectProps={{
        MenuProps: {
          PaperProps: { sx: { maxHeight: props?.maxMenuHeight || 350 } },
        },
      }}
      {...props}
    >
      {menuItems ||
        (props?.items || [])?.map((item, index) => (
          <MenuItem
            key={index}
            value={item?.value}
            sx={{
              display: item?.hidden && "none",
              minHeight: "36px !important",
            }}
          >
            {item?.label}
          </MenuItem>
        ))}
    </TextField>
  );
}
