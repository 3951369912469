import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  sampleStepsBox: {
    '&::-webkit-scrollbar': {
      height: 8
    },
    '&::-webkit-scrollbar-track': {
      display: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#E0E0E0',
      borderRadius: 12
    },
  },
  grayText: {
    color: '#828282'
  },
  stepBox: {
    position: 'relative',
    minWidth: 250,
  },
  step: {
    display: 'flex',
    borderRadius: 8,
    "& img": {
      cursor: "pointer"
    },
    marginLeft: 16
  },
  leafve: {
    position: 'absolute',
    background: '#B7DEC1',
    width: 10,
    height: 17,
    borderRadius: '75% 0 75% 0',
  }
}));
