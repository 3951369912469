import { Link, styled } from "@mui/material";
import { FRESHDI_LOGO_ICON } from "assets/icons";
import { FRESHDI_HOME_DOMAIN } from "constants/schemas";
import { addUtmParam } from "helpers/search";

export default function FreshdiLogo() {

  return (
    <StyledLink underline="none" href={addUtmParam(FRESHDI_HOME_DOMAIN)}>
      <img className="logo" src={FRESHDI_LOGO_ICON} alt="logo" />
    </StyledLink>
  );
}

const StyledLink = styled(Link)({
  ".logo": {
    height: 48,
  },
  p: {
    fontSize: 8,
    color: "#000",
  },
});
