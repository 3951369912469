import { Grid, styled } from "@mui/material";

export const StyledQuoteContactTips = styled(Grid)(({ theme }) => ({
  ".content": {
    padding: "12px 27px",
    backgroundColor: "var(--brand-25)",
    borderRadius: 8,
    img: {
      height: 30,
      width: 30,
    },
    ".text": {
      color: "var(--brand-500)",
      span: {
        color: "var(--yellow-500)",
      },
    },
    button: {
      whiteSpace: "nowrap",
    },
  },

  [theme.breakpoints.down("md")]: {
    ".content": {
      ".text, button": {
        fontSize: 14,
        lineHeight: "20px",
      },
    },
  },
}));

export const StyledDiv = styled("div")({
  padding: 12,
  backgroundColor: "var(--brand-25)",
  borderRadius: 8,
  boxShadow:
    "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
  ".title": {
    display: "flex",
    gap: 4,
    marginBottom: 4,
    color: "var(--brand-500)",
  },
  ".desc-text": {
    color: "var(--brand-500)",
    a: {
      color: "var(--yellow-500)",
      textDecoration: "underline",
    },
    span: {
      "&.semibold": {
        fontWeight: 600,
      },
    },
  },
});

export const StyledUpgradePlanToConnectBuyerTips = styled(Grid)(
  ({ theme }) => ({
    ".content": {
      padding: "12px 27px",
      backgroundColor: "var(--yellow-25)",
      borderRadius: 8,

      ".text": {
        color: "var(--yellow-500)",
        span: {
          fontWeight: 600,
        },
      },
      button: {
        whiteSpace: "nowrap",
        fontWeight: 600,
      },
      ".icon-box": {
        height: 30,
        width: 30,
        backgroundColor: "var(--yellow-100)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        img: {
          height: 15,
          width: 15,
        },
      },
    },

    [theme.breakpoints.down("md")]: {
      ".content": {
        ".text, button": {
          fontSize: 14,
          lineHeight: "20px",
        },
      },
    },
  })
);
