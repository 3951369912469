import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "services/redux/selectors/userSelector";
import useNavigate from "./useNavigate";
import { PATHS, SEARCH_PARAMS, SUBDOMAINS } from "constants/routes";
import { useLoading } from "providers/loading";
import { apiGetUserInformation, apiLogoutUser } from "services/api/user";
// import { logoutUser, setUser } from "redux/actions/user";
// import { setCustomer } from "Actions/userActions";
import { decodeBase64, encodeBase64 } from "helpers/base64";
import { logoutUser } from "services/redux/actions/user";
import { FRESHDI_HOME_DOMAIN } from "constants/schemas";
import { setChatList } from "services/redux/actions/chat";
import { getSubdomain } from "helpers/url";
import { addUtmParam } from "helpers/search";

const useAuth = () => {
  const user = useSelector(userSelector);
  const { navigate, useSearchParams } = useNavigate();
  const { setLoading } = useLoading();

  const dispatch = useDispatch();
  const isLoggedIn = useMemo(() => user?.is_logged_in, [user?.is_logged_in]);
  const currentBalance = useMemo(
    () => user?.balance?.balance || 0,
    [user?.balance?.balance]
  );

  const getLoginUrl = useMemo(() => {
    let currentUrl = window.location.href;
    return addUtmParam(
      `${FRESHDI_HOME_DOMAIN}${PATHS.login}?${
        SEARCH_PARAMS.directUrl
      }=${encodeBase64(currentUrl)}`
    );
  }, []);

  const onDirectLogin = useCallback(() => {
    window.location.href = getLoginUrl;
  }, []);

  const onDirectSignup = useCallback(() => {
    let currentUrl = window.location.href;
    window.location.href = addUtmParam(
      `${FRESHDI_HOME_DOMAIN}${PATHS.register}?${
        SEARCH_PARAMS.directUrl
      }=${encodeBase64(currentUrl)}`
    );
  }, []);

  const onLogout = useCallback(async () => {
    setLoading(true);
    return await apiLogoutUser()
      .then((res) => {
        dispatch(logoutUser());
        setLoading(false);
        dispatch(setChatList([]));
        const matchDirectSr = getSubdomain() === SUBDOMAINS.sourcing;
        if (!matchDirectSr) {
          window.location.href = PATHS.sourcingRequestsFull;
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [dispatch, setLoading]);

  const onGetUserInformation = useCallback(async () => {
    return apiGetUserInformation().then((res) => {
      setLoading(false);
      let newUserData = res?.data?.message?.data || {};
      newUserData["freshdi_customer_id"] = newUserData?.name;
      // dispatch(setUser(newUserData));
      // dispatch(setCustomer(newUserData));
    });
  }, [dispatch, useSearchParams]);

  const onDirectNextPageOfLogin = useCallback(() => {
    let directPath = useSearchParams.get(SEARCH_PARAMS.directUrl);
    if (directPath) {
      navigate(decodeBase64(directPath));
    } else {
      navigate(-1);
    }
  }, []);

  return useMemo(
    () => ({
      isLoggedIn,
      currentBalance,
      getLoginUrl,
      onDirectLogin,
      onDirectSignup,
      onLogout,
      onGetUserInformation,
      onDirectNextPageOfLogin,
    }),
    [
      isLoggedIn,
      currentBalance,
      getLoginUrl,
      onDirectLogin,
      onDirectSignup,
      onLogout,
      onGetUserInformation,
      onDirectNextPageOfLogin,
    ]
  );
};

export default useAuth;
