import React from "react";
import { Box, Skeleton } from "@mui/material";

export default function CustomSkeleton(props) {
  const {
    variant = "text",
    alignItems = "center",
    boxWidth = '100%',
    skeWidth = 100,
    animation = "wave",
    loop = 1,
    boxAtt = {}
  } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: alignItems,
        width: boxWidth,
        ...boxAtt
      }}
    >
      {Array.from({ length: loop }, (_, i) => (
        <Skeleton
          variant={variant}
          width={skeWidth}
          animation={animation}
          key={i}
        />
      ))}
    </Box>
  );
}
