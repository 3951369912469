const initialState = {
  selectedQr: null,
  reloadQrDetail: false,
  qrListFilter: { category: "all" },
};

const qrReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_QR": {
      return {
        ...state,
        selectedQr: action.payload,
      };
    }

    case "RELOAD_QR_DETAIL": {
      return {
        ...state,
        reloadQrDetail: !state?.reloadQrDetail,
      };
    }

    case "SET_QRLIST_FILTER": {
      return {
        ...state,
        qrListFilter: action.payload,
      };
    }

    default:
      return state;
  }
};

export default qrReducer;
