const initialState = {
  voucherCampaigns: [],
  commonVoucherCampaigns: [],
  isLoading: true,
  selectedVoucherCampaign: null,
};

const voucherCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_VOUCHERS_CAMPAIGNS": {
      return {
        ...state,
        voucherCampaigns: action.payload,
        isLoading: action.isLoading,
      };
    }

    case "GET_COMMON_VOUCHERS_CAMPAIGNS": {
      return {
        ...state,
        commonVoucherCampaigns: action.payload,
      };
    }

    case "UPDATE_COMMON_VOUCHERS_CAMPAIGNS": {
      return {
        ...state,
        commonVoucherCampaigns: state.commonVoucherCampaigns.map((campaign) => {
          if (campaign.name === action.payload.name) {
            return {
              ...campaign,
              ...action.payload,
              cp_status: !action.payload.cp_status,
            };
          }
          return campaign;
        }),
      };
    }

    case "SET_SELECTED_VOUCHER_CAMPAIGN": {
      return {
        ...state,
        selectedVoucherCampaign: action.payload,
      };
    }

    case "UPDATE_VOUCHERS_CAMPAIGNS": {
      return {
        ...state,
        voucherCampaigns: state.voucherCampaigns.map((campaign) => {
          if (campaign.name === action.payload.name) {
            return {
              ...campaign,
              ...action.payload,
              cp_status: action.payload.cp_status === 1 ? 0 : 1,
            };
          }
          return campaign;
        }),
      };
    }
    default:
      return state;
  }
};

export default voucherCampaignReducer;
