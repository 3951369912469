const initialState = {
  selectedOptionIdx: 0,
  showDrawer: false,
  splitRight: null,
  splitLeft: null,
  splitRightPopup: {
    open: false,
    content: null,
  },
  splitLeftPopup: {
    open: false,
    content: null,
  },
  cornerIcon: {
    open: false,
    content: null,
    side: "left",
  },
  printComponent: {
    open: false,
    content: null,
    printComponentRef: null,
  },
  waiting: false,
  errorPopup: {
    open: false,
    text: "",
  },
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SHOW_DRAWER": {
      return {
        ...state,
        showDrawer: action.payload,
      };
    }

    case "SET_SELECTED_OPTION_IDX": {
      return {
        ...state,
        selectedOptionIdx: action.payload,
      };
    }

    case "CHANGE_SPLIT_RIGHT": {
      return {
        ...state,
        splitRight: action.payload,
      };
    }

    case "CHANGE_SPLIT_LEFT": {
      return {
        ...state,
        splitLeft: action.payload,
      };
    }

    case "CHANGE_SPLIT_RIGHT_POPUP": {
      return {
        ...state,
        splitRightPopup: {
          ...state?.splitRightPopup,
          content: action.payload?.data,
          att: action.payload?.att,
        },
      };
    }

    case "TOGGER_SPLIT_RIGHT_POPUP": {
      return {
        ...state,
        splitRightPopup: { ...state?.splitRightPopup, open: action.payload },
      };
    }

    case "CHANGE_SPLIT_LEFT_POPUP": {
      return {
        ...state,
        splitLeftPopup: {
          ...state?.splitLeftPopup,
          content: action.payload?.data,
          att: action.payload?.att,
        },
      };
    }

    case "TOGGER_SPLIT_LEFT_POPUP": {
      return {
        ...state,
        splitLeftPopup: { ...state?.splitLeftPopup, open: action.payload },
      };
    }

    case "CHANGE_PRINT_COMPONENT": {
      return {
        ...state,
        printComponent: action.payload,
      };
    }

    case "CHANGE_CORNER_ICON": {
      return {
        ...state,
        cornerIcon: { ...state?.cornerIcon, content: action.payload },
      };
    }

    case "TOGGER_CORNER_ICON": {
      return {
        ...state,
        cornerIcon: { ...state?.cornerIcon, open: action.payload },
      };
    }

    case "CHANGE_SIDE_CORNER_ICON": {
      return {
        ...state,
        cornerIcon: { ...state?.cornerIcon, side: action.payload },
      };
    }

    case "CHANGE_WAITING": {
      return {
        ...state,
        waiting: action.payload,
      };
    }

    case "CHANGE_ERROR_POPUP": {
      return {
        ...state,
        errorPopup: { ...state?.errorPopup, ...action.payload },
      };
    }

    default:
      return state;
  }
};

export default layoutReducer;
