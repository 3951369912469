export const getBrowserLanguage = () => {
  const browserLanguage = navigator.language;
  switch (browserLanguage) {
    case "en-US":
      return "en";
    case "vi":
      return "vi";

    default:
      return "en";
  }
};
