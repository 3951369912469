import { Box, Typography } from "@mui/material";
import ConfirmBtns from "./ConfirmBtns";

const ConfirmBox = ({ title, text, onCancle, onConfirm, ...otherProps }) => {
  return (
    <Box {...otherProps}>
      <Typography fontWeight={600} align="center">
        {title}
      </Typography>
      <Typography mt={2}>{text}</Typography>

      <ConfirmBtns {...{ onCancle, onConfirm }} />
    </Box>
  );
};

export default ConfirmBox;
