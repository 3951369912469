import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  addBtnBox: {
    color: "var(--text-yellow-color)",
    "& input": {
      visibility: "hidden",
      display: "none",
    },
    "&, .addBox": {
      alignItems: "center",
      display: "flex",
    },
    cursor: "pointer",
  },
}));
