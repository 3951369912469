import React, { useEffect, useRef, useState } from "react";
import { Typography, Box } from "@mui/material";
import useStyles from "./styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export default function ShowMoreText({
  content,
  maxLine = 6,
  att,
  conAtt,
  morAtt,
  handleClickMore,
  exp,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(exp);
  const [showBtn, setShowBtn] = useState(true);
  const textboxRef = useRef();

  useEffect(() => {
    if (textboxRef.current) {
      const isShowBtn =
        textboxRef.current.offsetHeight < textboxRef.current.scrollHeight;
      setShowBtn(isShowBtn);
      // setShowBtn(true);
    }
  }, [content]);

  return (
    <Box sx={{ ...att }} className={classes.main}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <div
          ref={textboxRef}
          className={classes.aboutText}
          style={{
            WebkitLineClamp: expanded ? "unset" : maxLine,
            display: expanded ? "inline" : "-webkit-box",
            ...conAtt,
          }}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
        {showBtn ? (
          <Box sx={{ ...morAtt }}>
            {expanded ? (
              <Typography
                className={classes.buttonShowMore}
                onClick={(e) => {
                  e?.stopPropagation();
                  setExpanded(false);
                }}
              >
                Thu gọn
                <ArrowDropUpIcon />
              </Typography>
            ) : (
              <Typography
                className={classes.buttonShowMore}
                onClick={(e) => {
                  e?.stopPropagation();
                  if (handleClickMore) handleClickMore();
                  setExpanded(true);
                }}
              >
                Mở rộng <ArrowDropDownIcon />
              </Typography>
            )}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
