import { Box, Link, styled } from "@mui/material";
import { PATHS } from "constants/routes";
import { VerifyTooltip } from "./Tooltip";
import { Text, XsTextMd } from "./Text";
import { Img } from "./Img";
import { CIRCLE_VERIFIED_ICON } from "assets/icons";
import { useIntl } from "react-intl";
import { useMemo } from "react";

const PlanLevel = ({ userLevel }: { userLevel: string }) => {
  return (
    <Link
      underline="none"
      href={PATHS.supplierPricing}
      target="_blank"
      rel="noopener noreferrer"
    >
      <VerifyTooltip title={<LevelTooltip level={userLevel} />}>
        <XsTextMd className="status-label green-label plan">
          <Img src={CIRCLE_VERIFIED_ICON} alt="icon" />
          {userLevel}
        </XsTextMd>
      </VerifyTooltip>
    </Link>
  );
};

const LevelTooltip = ({ level }: { level: string }) => {
  const intl = useIntl();
  const levelTexts = useMemo(
    () => [
      {
        label: "business_information",
        show: ["Level 2", "Level 3", "Level 4"].indexOf(level) > -1,
      },
      {
        label: "business_licenses",
        show: ["Level 3", "Level 4"].indexOf(level) > -1,
      },
      {
        label: "smart_trace_online",
        show: ["Level 4"].indexOf(level) > -1,
      },
    ],
    [level]
  );
  return (
    <StyledLevelTooltip>
      <Text>
        {intl.formatMessage({ id: "supplier_is_verified_by_freshdi" })}
      </Text>
      <ul>
        {levelTexts
          .filter((text) => text.show)
          .map((text) => (
            <li>
              <Text>{intl.formatMessage({ id: text.label })}</Text>
            </li>
          ))}
      </ul>
    </StyledLevelTooltip>
  );
};

export default PlanLevel;

const StyledLevelTooltip = styled(Box)({
  p: {
    fontSize: 10,
  },
  ul: {
    paddingInlineStart: 16,
  },
  "& ul li p": {},
});
