import { Box, Stack, Typography, styled } from "@mui/material";

import { Attachment, HtmlViewer, Link, Row } from "components";
import { DIRECT_PATHS } from "constants/routes";
import { formatImg } from "helpers/format";
import { QuotationChildType } from "types/quotaion";

const Quotation = ({ quotation }: { quotation: QuotationChildType }) => {
  return (
    <Link to={DIRECT_PATHS.myQuotations(true)}>
      <StyledQuotation className="quotation-card">
        <Row alignItems="flex-start">
          <img
            className="seller_img"
            src={formatImg(quotation.farm_avatar, 2)}
            alt="img"
          />
          <Box flex={1}>
            <Typography className="seller_name">
              {quotation?.user_full_name}
            </Typography>
            <Typography className="seller_desc">
              {quotation?.farm_name}
            </Typography>
            <Typography className="product_name">{quotation?.title}</Typography>

            <HtmlViewer
              content={quotation.details}
              fontSize={12}
              color="var(--gray)"
              mt={0.5}
            />

            <Stack gap={1} direction="row" flexWrap="wrap" mt={2}>
              {quotation?.media?.map((media, i) => (
                <Attachment attachment={media.url} key={i} />
              ))}
            </Stack>
          </Box>
        </Row>
      </StyledQuotation>
    </Link>
  );
};

const StyledQuotation = styled(Box)({
  padding: 16,
  borderRadius: 8,
  boxShadow:
    "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",
  cursor: "pointer",
  backgroundColor: "#fff",
  ".seller_img": {
    width: 32,
    height: 32,
    objectFit: "cover",
    borderRadius: "50%",
  },
  ".seller_name": {
    fontSize: 18,
    fontWeight: 600,
  },
  ".seller_desc, .product_desc, .quantity_text": {
    color: "var(--gray-color)",
  },
  ".product_name": {
    marginTop: 16,
    fontWeight: 700,
  },
  ".quantity_text": {
    fontWeight: 700,
    marginTop: 4,
  },
});

export default Quotation;
