import axios from "axios";
import { API_DOMAIN, DEFAULT_TOKEN } from "constants/schemas";

const http = axios.create({
  baseURL: API_DOMAIN,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    // ...(DEFAULT_TOKEN ? { Authorization: '' } : {}),
    ...(DEFAULT_TOKEN ? { Authorization: DEFAULT_TOKEN } : {}),
  },
  withCredentials: true,
});

export default http;
