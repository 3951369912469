import { Skeleton, Stack, StackProps, styled } from "@mui/material";

const LoadingSkeleton = (
  props: StackProps & { count?: number; loadingWidth?: number | string }
) => {
  const { count = 3, loadingWidth = 164 } = props;

  return (
    <StyledStack spacing={1} alignItems="center" width="100%" {...props}>
      {[...Array(count)].map((count, i) => (
        <Skeleton className="loading_box" key={i} width={loadingWidth} />
      ))}
    </StyledStack>
  );
};

const StyledStack = styled(Stack)({
  "& .loading_box": {
    height: 24,
  },
});

export { LoadingSkeleton };
