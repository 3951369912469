
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  qltBox: {
    '& svg': {
      color: '#E98100',
      fontSize: '20px'
    }
  },
  qltMainBox: {
    display: 'flex',
    alignItems: 'center'
  }
}))