import { Stack, Typography } from "@mui/material";
import { getShippingStatusConfigs } from "services/utils/orderHelpers";

const ShippingStatusBox = ({ status = 0 }) => {
  const configs = getShippingStatusConfigs(status);

  const { cl, bg, text } = { cl: configs[0], bg: configs[1], text: configs[2] };
  return (
    <Stack justifyContent="center" sx={{ color: cl, bgcolor: bg, px: "4px" }}>
      <Typography variant="body2" align="center">
        {text}
      </Typography>
    </Stack>
  );
};

export default ShippingStatusBox;
