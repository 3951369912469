import { SUBDOMAINS } from "constants/routes";
import { getSubdomain } from "helpers/url";
import { Suspense, lazy } from "react";
const BusinessRoutes = lazy(() => import("routes/BussinessRoutes"));
const SourcingRoutes = lazy(() => import("routes/SourcingRoutes"));

export const withSeparateApp = () => {
  const subdomain = getSubdomain();

  return (
    <Suspense fallback={null}>
      {subdomain === SUBDOMAINS.sourcing ? (
        <SourcingRoutes />
      ) : (
        <BusinessRoutes />
      )}
    </Suspense>
  );
};
