const initialState = {
  reloadShippingAgents: false,
  shippingAgents: [],
  loadingShippingAgents: false
};

const shippingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RELOAD_SHIPPING_AGENTS": {
      return {
        ...state,
        reloadShippingAgents: !state.reloadShippingAgents,
      };
    }

    case "SET_SHIPPING_AGENTS": {
      return {
        ...state,
        shippingAgents: action.payload,
      };
    }

    case "SET_LOADING_SHIPPING_AGENTS": {
      return {
        ...state,
        loadingShippingAgents: action.payload,
      };
    }

    default:
      return state;
  }
};

export default shippingReducer;
