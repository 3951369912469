import { CSSProperties, FC } from "react";

interface ImageProps {
  src?: string;
  alt?: string;
  ratio?: "ratio16x9" | "ratio1x1" | "ratio42x159";
  loading?: "lazy" | "eager";
  className?: string;
  size?: "cover" | "contain";
  style?: CSSProperties;
}

export const Img: FC<ImageProps> = ({
  src,
  alt,
  loading = "lazy",
  ...props
}) => <img src={src} alt={alt} loading={loading} {...props} />;