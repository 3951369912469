import whiteVoucherIcon from "../assets/icons/white_voucher.svg";
import redDeleteIcon from "../assets/icons/red_delete.svg";
import addImgIcon from "../assets/images/default/add_image.png";
import controlIcon from "../assets/icons/control.svg";
import partnerVerifyIcon from "../assets/icons/partner_verify.svg";
import partnerUnverifyIcon from "../assets/icons/partner_unverify.svg";
import domainIcon from "../assets/icons/domain.svg";
import chessBoardIcon from "../assets/icons/chessboard.svg";
import mulAccountIcon from "../assets/icons/mul_account.svg";
import downloadIcon from "../assets/icons/download.svg";
import shareIcon from "../assets/icons/bg_share.svg";
import editIcon from "../assets/icons/edit.svg";
import payIcon from "../assets/icons/clipboard.svg";
import advanceIcon from "../assets/icons/advance.svg";
import bagIcon from "../assets/icons/bag.svg";
import shippingIcon from "../assets/icons/shipping.svg";
import shippingBoxIcon from "../assets/images/shipping/box.svg";

export default {
  WHITE_VC_ICON: whiteVoucherIcon,
  SLOGAN_TEXT: "Nền tảng Bán hàng & CSKH",
};

export const OTP_LIMIT_TIME = 60;

// Icon
export const CONTROL_ICON = controlIcon;
export const PARTNER_VERIFY_ICON = partnerVerifyIcon;
export const PARTNER_UNVERIFY_ICON = partnerUnverifyIcon;
export const DOMAIN_ICON = domainIcon;
export const CHESS_BOARD_ICON = chessBoardIcon;
export const MUL_ACCOUNT_ICON = mulAccountIcon;
export const EDIT_ICON = editIcon;
export const RED_REMOVE_ICON = redDeleteIcon;
export const PAY_ICON = payIcon;
export const ADVANCE_ICON = advanceIcon;
export const BAG_ICON = bagIcon;
export const SHIPPING_ICON = shippingIcon;

// Image
export const DOWNLOAD_ICON = downloadIcon;
export const SHARE_ICON = shareIcon;
export const ADD_IMG_ICON = addImgIcon;
export const SHIPPING_BOX_ICON = shippingBoxIcon;

// Link
export const HELP_LINK =
  "https://help.freshdi.com/o.-tai-lieu-huong-dan-freshdi/tai-lieu-huong-dan-su-dung-website-quan-tri";

// Search params
const STAMP_ID = "stamp-id";
const STAMP_EDIT = "stamp-edit";
const STAMP_LIST = "stamp-list";
const STAMP_CREATE = "stamp-create";
const BATCH_ID = "batch-id";
const CREATE_BATCH = "create-batch";
const DIRECT_URL = "url";

// PASSWORD
export const MAX_PASSWORD_LENGTH = 6;

// Error message
export const NOT_EMPTY = "Vui lòng nhập thông tin";
export const NOT_IS_VALID_PHONE = "Số điện thoại không hợp lệ";
export const FOR_HIDDEN = "Tài khoản của bạn không đủ quyền";
export const INVALID_DATA = "Dữ liệu không hợp lệ";
export const DEFAULT_ERROR_MSG = "Có lỗi xảy ra";
export const LIMIT_PW_ERROR_MSG = `Mật khẩu cho phép cần có từ ${MAX_PASSWORD_LENGTH} ký tự`;

// Role
export const ADMIN_ROLE_ID = "farm_admin";

export {
  STAMP_ID,
  STAMP_CREATE,
  STAMP_EDIT,
  STAMP_LIST,
  BATCH_ID,
  CREATE_BATCH,
  DIRECT_URL,
};
