import { Snackbar } from "@mui/material";
import React from "react";

const snackbarAtt = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%) !important",
  "& .MuiSnackbarContent-root": {
    justifyContent: "center",
    minWidth: "90px !important",
  },
};

const CustomSnackbar = ({ openSnackbar, handleCloseSnackbar, ...props }) => {
  return (
    <Snackbar
      sx={snackbarAtt}
      anchorOrigin={{ vertical: "center", horizontal: "center" }}
      open={openSnackbar}
      onClose={handleCloseSnackbar}
      autoHideDuration={1500}
      message={props?.message || "Lưu thành công"}
      ContentProps={{
        sx: {
          borderRadius: 3,
          p: "4px 14px",
          bgcolor: "#4D4D4D",
        },
      }}
    />
  );
};

export default CustomSnackbar;
