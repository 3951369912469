import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  main: {
    backgroundColor: "rgba(33, 150, 83, 0.1)",
    borderRadius: 4,
    padding: 6,
    "& p": { 
      whiteSpace: 'nowrap'
    },
    display: 'flex',
    alignItems: 'center',
    "& svg": {
      marginLeft: 6
    }
  },
}));
