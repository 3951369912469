import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  tabBox: {
    '& p': {
      textTransform: 'intital'
    }
  },
  tab: {
    textTransform: 'initial !important'
  }
}))