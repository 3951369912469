import { DependencyList, useEffect, useMemo, useState } from "react";

const useGetData = ({
  promise,
  rejectEvent,
  deps = [],
}: {
  promise: any;
  rejectEvent?: any;
  deps?: DependencyList;
}) => {
  const [isLoading, setisLoading] = useState(true);
  const [data, setData] = useState<any>([]);

  const fetchData = async () => {
    try {
      let data = (await promise()) as any;
      setData(data?.data?.message || []);
      setisLoading(false);
    } catch (error) {
      console.log(error);
      rejectEvent?.(error);
      setisLoading(false);
      setData({});
    }
  };

  useEffect(() => {
    setisLoading(true);
    fetchData();
  }, [...deps]);

  return useMemo(
    () => ({
      isLoading,
      data,
    }),
    [isLoading, data]
  );
};

export default useGetData;
