import { useEffect, useRef, useState } from "react";
import useStyles from "./styles";
import { Box, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, A11y } from "swiper";
import 'swiper/css';
import CustomImageWithBg from "../CustomImageWithBg";

SwiperCore.use([Navigation, A11y]);

export default function CustomImages({
  images,
  show = false,
  handleShow,
  defaultIndex = 0
}) {
  const imgRef = useRef(null);
  const leftBtnRef = useRef(null);
  const rightBtnRef = useRef(null);
  const dotRef = useRef(null);
  const contentRef = useRef(null);
  const classes = useStyles();
  const [startIndex, setIndex] = useState(defaultIndex);

  const clickBack = () => {
    if (images && images.length > 0) {
      setIndex(startIndex === 0 ? images.length - 1 : startIndex - 1);
    }
  };

  const clickNext = () => {
    if (images && images.length > 0) {
      setIndex(startIndex === images.length - 1 ? 0 : startIndex + 1);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contentRef.current && !contentRef.current.contains(event.target)) {
        if (dotRef.current && !dotRef.current.contains(event.target)) {
          handleShow(false);
        }
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [imgRef, leftBtnRef, rightBtnRef, handleShow]);

  useEffect(() => {
    setIndex(defaultIndex);
  }, [images, defaultIndex]);

  return (
    <>
      {show && (
        <Box className={classes.background}>
          <Box className={classes.content} ref={contentRef}>
            <IconButton className="swiper-button-prev" onClick={clickBack}>
              <ArrowBackIos onClick={clickBack} ref={leftBtnRef} />
            </IconButton>
            <Swiper
              loop={true}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              slidesPerView={1}
              onSlideChange={(e) => setIndex(e?.realIndex)}
              initialSlide={defaultIndex}
            >
              {images?.map((image, index) => (
                <SwiperSlide key={index}>
                  <CustomImageWithBg src={image?.url} setRef={imgRef} />
                </SwiperSlide>
              ))}
            </Swiper>
            <IconButton className="swiper-button-next" onClick={clickNext}>
              <ArrowForwardIos onClick={clickNext} ref={rightBtnRef} />
            </IconButton>
          </Box>
          <Box className={classes.dots} mt={2} ref={dotRef}>
            {images?.map((image, index) => (
              <Box
                className={classes.dot}
                key={index}
                p={{ xs: "6px", md: 1 }}
                mr={{ xs: 1, md: 2 }}
                sx={{
                  backgroundColor: index === startIndex ? "#ddd" : "#6c6c6c",
                }}
                onClick={() => setIndex(index)}
              />
            ))}
          </Box>
        </Box>
      )}
    </>
  );
}
