import { GetSuggestedPriceProductType } from "types/supplier";
import http from "./http";

const ENDPOINT = "/api/method/freshdi.api.web.v3";

export const apiGetUnseenRecommended = () =>
  http.get(`${ENDPOINT}.supplier.count_unseen_recommended`);

export const apiGetCountNotiMenuBar = () =>
  http.get(`${ENDPOINT}.supplier.count_noti_menu_bar`);

export const apiGetSuggestedPriceProduct = (
  params?: GetSuggestedPriceProductType
) => http.get(`${ENDPOINT}.supplier.get_suggested_price_product`, { params });
