import { Box } from "@mui/material";
import BoxClickOut from "components/BoxClickOut";
import Title from "components/Title";
import React from "react";

import useStyles from "./styles";

const ErrorPopup = ({ onClickOut, text }) => {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <BoxClickOut
        onClickOut={onClickOut}
        children={
          <Box p={4}>
            <Title text={text} />
          </Box>
        }
        className={classes.content}
      />
    </Box>
  );
};

export default ErrorPopup;
