import { Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useStyles from "./styles";

const CustomTabs = ({ tabs = [] }) => {
  const classes = useStyles();
  const { qrCategoryId } = useParams();
  const nav = useNavigate();

  useEffect(() => {
    if (!qrCategoryId) {
      nav(`/manage-qr/all`);
    }
  }, []);

  const onChange = (e, newValue) => {
    nav(`/manage-qr/${newValue}`);
  };

  return (
    <Tabs
      variant="scrollable"
      scrollButtons={false}
      TabIndicatorProps={{ style: { background: "#008934" } }}
      value={qrCategoryId || ""}
      onChange={onChange}
    >
      {tabs?.map((tab, i) => (
        <Tab
          key={i}
          label={tab.label}
          className={classes.tab}
          value={tab?.value}
          sx={{
            fontSize: "14px",
            color:
              qrCategoryId === tab?.value ? "#008934 !important" : "#828282",
            minWidth: "fit-content",
            p: 0,
            mr: 3,
          }}
        />
      ))}
    </Tabs>
  );
};

export default CustomTabs;
