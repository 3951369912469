import http from "./http";

const V3_ENDPOINT = "/api/method/freshdi.api.web.v3";

export const apiPurchaseContact = (data: any) =>
  http.post(`${V3_ENDPOINT}.contact.farm_purchase_contact`, data);

export const apiGetContactDetail = (data: any) =>
  http.get(`${V3_ENDPOINT}.contact.farm_get_owned_contact`, { params: data });

export const apiGetContacts = (data: any) =>
  http.get(`${V3_ENDPOINT}.contact.farm_get_contacts`, { params: data });
