import { Box, Button, styled } from "@mui/material";
import { useIntl } from "react-intl";

const ConfirmBtns = ({
  cancleText = "Cancel",
  onCancle = () => {},
  confirmText = "submit",
  onConfirm = () => {},
  sx,
}) => {
  const intl = useIntl();
  return (
    <Allbtnbox {...{ sx }}>
      <Button onClick={onCancle} className="cancleBtn">
        {intl.formatMessage({ id: cancleText })}
      </Button>
      <Button
        sx={{ color: "#008934 !important" }}
        className="confirmBtn"
        onClick={onConfirm}
      >
        {intl.formatMessage({ id: confirmText })}
      </Button>
    </Allbtnbox>
  );
};

const Allbtnbox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  "& button": {
    textTransform: "initial !important",
    color: "#000",
  },
});

export default ConfirmBtns;
