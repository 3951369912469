import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import useStyles from "./styles";

const ChangePage = ({
  handleClickPrePage,
  handleClickNextPage,
  offset,
  limit,
  total,
  att,
}) => {
  const classes = useStyles();

  const [currentIndex, setCurrentIndex] = useState(1);

  useEffect(() => {
    let tmpCurrentIndex = Math.ceil(offset / limit),
      maxIndex = Math.ceil(total / limit);

    setCurrentIndex(
      tmpCurrentIndex + 1 <= maxIndex
        ? Math.ceil(offset / limit) + 1
        : Math.ceil(total / limit) + 1
    );
  }, [offset, limit, total]);

  return (
    <Box className={classes.pageBox} sx={{ ...att }}>
      <IconButton onClick={handleClickPrePage}>
        <ArrowBackIosIcon />
      </IconButton>

      {`${currentIndex} / ${Math.ceil(total / limit)}`}

      <IconButton onClick={handleClickNextPage}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default ChangePage;
