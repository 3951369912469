import dayjs from "dayjs";

export const formatDatetime = (
  datetime?: string,
  format: string = "DD/MM/YYYY"
) => {
  return datetime ? dayjs(datetime).format(format) : "";
};

export const formatDate = (date: string) => {
  return date && date.split("-").reverse().join("/");
};

export const dateDiffWithTimestamp = (str1: any, str2: any) => {
  var second = 1000,
    minute = second * 60,
    hour = minute * 60,
    day = hour * 24,
    week = day * 7;
  let date1 = new Date(str1);
  let date2 = new Date(str2);
  var diff = date2.getTime() - date1.getTime();
  return isNaN(diff)
    ? NaN
    : {
        diff: diff,
        second: Math.floor(diff / second),
        minute: Math.floor(diff / minute),
        hour: Math.floor(diff / hour),
        day: Math.floor(diff / day),
        week: Math.floor(diff / week),
        month:
          date2.getFullYear() * 12 +
          date2.getMonth() -
          (date1.getFullYear() * 12 + date1.getMonth()),
      };
};

export const dateDiff = (
  str1: string,
  str2: string,
  type: string = "mwdhm"
) => {
  let date1 = dayjs(str1);
  let date2 = dayjs(str2);

  // TYPE: Month, week, day, hour, minute
  if (type === "mwdhm") {
    let monthAgo = date2.diff(date1, "month");
    let weekAgo = monthAgo > 0 ? 0 : date2.diff(date1, "week");
    let weekBonusAgo = monthAgo > 0 ? 0 : weekAgo % 7;
    let dayAgo = weekAgo > 0 ? 0 : date2.diff(date1, "day");
    let dayBonusAgo = weekBonusAgo > 0 || monthAgo > 0 ? 0 : dayAgo % 30;
    let hourAgo = date2.diff(date1, "hour");
    let hourBonusAgo = weekBonusAgo > 0 || monthAgo > 0 ? 0 : hourAgo % 24;
    let minuteAgo = date2.diff(date1, "minute");
    let minuteBonusAgo =
      weekBonusAgo > 0 || monthAgo > 0 || dayAgo > 0 ? 0 : minuteAgo % 60;

    return {
      month: monthAgo,
      week: weekBonusAgo,
      day: dayBonusAgo,
      hour: hourBonusAgo,
      minute: minuteBonusAgo,
    };
  }

  // TYPE: Day, hour, minute
  if (type === "dhm") {
    let dayAgo = date2.diff(date1, "day");
    let hourAgo = date2.diff(date1, "hour");
    let hourBonusAgo = hourAgo % 24;
    let minuteAgo = date2.diff(date1, "minute");
    let minuteBonusAgo = dayAgo > 0 ? 0 : minuteAgo % 60;
    return { day: dayAgo, hour: hourBonusAgo, minute: minuteBonusAgo };
  }

  return {};
};

export const convertToFullMonth = (text?: string, lang: string = "en-GB") => {
  if (text) {
    const dateObject = new Date(text);
    const options: any = { day: "numeric", month: "long", year: "numeric" };
    return dateObject.toLocaleDateString(lang, options);
  }
  return "";
};

export const getCurrentDateZone = (lang: string) => {
  switch (lang) {
    case "vi":
      return "vi-VN";

    default:
      return "en-GB";
  }
};

export function getDatesBetween(startDate?: Date, endDate?: Date) {
  const dates = [];
  if (startDate && endDate) {
    let currentDate = dayjs(startDate);
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

    while (currentDate.isBefore(formattedEndDate)) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }
  }

  return dates;
}

export function formatShortDate(inputDate: Date) {
  return dayjs(inputDate).format("MMM DD");
}

export const formatChatDateTime = (
  dateTime: Date | string,
  format: { same: string; other: string } = {
    same: "h:mm A",
    other: "h:mm A DD/MM/YYYY",
  }
): string => {
  const date = dayjs(dateTime);
  const today = dayjs();
  if (date.isSame(today, "day")) {
    return date.format(format.same);
  } else {
    return date.format(format.other);
  }
};

export const isMoreThanDaysBefore = (
  timestamp?: string | null,
  daysCount: number = 0
) => {
  if (!timestamp) return false;
  const inputDate = dayjs(timestamp);
  const today = dayjs().startOf("day");
  const diffDays = today.diff(inputDate, "day");
  return diffDays > daysCount;
};
