import { withLoading } from "hocs/withLoading";
import { lazy } from "react";

export { default as RoundInputWithLabel } from "./RoundInputWithLabel";
export { default as ExpandMore } from "./ExpandMore";
export { default as SearchInput } from "./SearchInput";
export { default as Title } from "./Title";
export { default as Loading } from "./Loading";
export { default as ChangeQuantityBox } from "./ChangeQuantityBox";
export { default as HorList } from "./HorList";
export { default as CustomImages } from "./CustomImages";
export { default as Wait } from "./Wait";
export { default as CustomSkeleton } from "./CustomSkeleton";
export { default as CustomButton } from "./CustomButton";
export { default as LineBreak } from "./LineBreak";
export { default as PlusButton } from "./PlusButton";
export { default as LimitLineText } from "./LimitLineText";
export { default as BoxClickOut } from "./BoxClickOut";
export { default as NotificationItem } from "./NotificationItem";
export { default as CustomDialog } from "./CustomDialog";
export { default as NullBox } from "./NullBox";
export { default as MultipleSelect } from "./MultipleSelect";
export { default as SingleSelect } from "./SingleSelect";
export { default as ChangePage } from "./ChangePage";
export { default as SnackbarVoucher } from "./SnackbarVoucher";
export { default as ItemPrice } from "./ItemPrice";
export { default as IOSSwitch } from "./IOSSwitch";
export { default as LineSplitSection } from "./LineSplitSection";
export { default as StepList } from "./StepList";
export { default as ErrorPopup } from "./ErrorPopup";
export { default as CustomDrawer } from "./CustomDrawer";
export { default as TagBox } from "./TagBox";
export { default as ChartDonut } from "./ChartDonut";
export { default as NumberTextField } from "./NumberTextField";
export { default as TakePhoto } from "./TakePhoto";
export { default as OrderFilter } from "./OrderFilter";
export { default as SearchPeople } from "./SearchPeople";
export { default as CustomTabs } from "./CustomTabs";
export { default as QrImage } from "./QrImage";
export { default as UpdateAreaDialog } from "./UpdateAreaDialog";
export { default as DynamicMedia } from "./DynamicMedia";
export { default as UploadProcess } from "./UploadProcess";
export { default as CustomFileInput } from "./CustomFileInput";
export { default as DynamicThumbnail } from "./DynamicThumbnail";
export { default as CustomSnackbar } from "./CustomSnackbar";
export { default as ShowMoreText } from "./ShowMoreText";
export { default as AddBox } from "./AddBox";
export { default as GoogleMap } from "./GoogleMap";
// export { default as ViewVerifyImage } from "./ViewVerifyImage";
// export { default as BuyContactForm } from "./BuyContactForm";
export { default as ToggerError } from "./ToggerError";
export { default as ConfirmBtns } from "./ConfirmBtns";
export { default as ConfirmBox } from "./ConfirmBox";
export { default as HtmlEditor } from "./HtmlEditor";
export { default as HtmlViewer } from "./HtmlViewer";
export { default as DOMAddressType } from "./DOMAddressType";
export { default as ShippingStatusBox } from "./ShippingStatusBox";
export { default as OrderStatusBox } from "./OrderStatusBox";
export { default as Camera } from "./Camera";
export { default as Cameras } from "./Cameras";
export { default as Row } from "./Row";
export { default as Pagnigation } from "./Pagnigation";
export { default as QuotationStatus } from "./QuotationStatus";
export { default as Quotation } from "./Quotation";
export { default as TextOnLine } from "./TextOnLine";
export { default as IntlMsg } from "./IntlMsg";
export { default as Tabs } from "./Tabs";
export { default as TimeDiff } from "./TimeDiff";
export { default as Attachment } from "./Attachment";
export { default as FreshdiLogo } from "./FreshdiLogo";
export { default as SelectLanguage } from "./SelectLanguage";
export { default as AuthBar } from "./AuthBar";
export { default as Menu } from "./Menu";
export { default as RFQCompanyBox } from "./RFQCompanyBox";
export { default as Breadcrumbs } from "./Breadcrumbs";
export { default as HeaderServices } from "./HeaderServices";
export { default as ScrollToTop } from "./ScrollToTop";
export { default as GetBusinessCard } from "./GetBusinessCard";
export * from "./ErrorText";
export * from "./Container";
export * from "./Img";
export * from "./LoadingSkeleton";
export * from "./Button";
export * from "./Input";
export * from "./CircularLoading";
export * from "./Text";
export * from "./ErrorText";
export { default as UploadFiles } from "./UploadFiles";
export { default as CompanyBox } from "./CompanyBox";
export { default as Company } from "./Company";
export { default as Swiper } from "./Swiper";
export { default as Categories } from "./Categories";
export { default as RFQ } from "./RFQ";
export { default as Blocker } from "./Blocker";
export { default as OTPInput } from "./OTPInput";
export const ChipList = lazy(() => import("./ChipList"));
export * from "./Tooltip";
export { default as PlanLevel } from "./PlanLevel";
export { default as Notification } from "./Notification";
export { default as ContactBuyerBlocker } from "./ContactBuyerBlocker";
export { default as Lock } from "./Lock";
export const SwitchAccountPopup = lazy(() => import("./SwitchAccountPopup"));
export const RelatedSearches = lazy(() => import("./RelatedSearches"));
export const Inquiry = lazy(() => import("./Inquiry"));
export { default as Popover } from "./Popover";
export const HeaderChat = lazy(() => import("./HeaderChat"));
export const ChatList = lazy(() => import("./ChatList"));
export const Chat = lazy(() => import("./Chat"));
export const MobileChatDetail = lazy(() => import("./MobileChatDetail"));
export { default as StyledImg } from "./StyledImg";
export const ReportForm = withLoading(
  lazy(() => import("./ReportForm")),
  2
);
export * from "./Autocomplete";
export * from "./Tips";
export * from "./Status";
export * from "./TipBanners";
export * from "./AnimationText";
export * from "./Checkbox";
