import { Drawer } from "@mui/material";
import React from "react";

import useStyles from "./styles";

const CustomDrawer = ({ open, onClose, content, maxWidth, att }) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      sx={{
        "& , & .MuiPaper-root, .MuiBackdrop-root": { maxWidth: maxWidth },
        ...att,
      }}
      className={classes.main}
    >
      {content}
    </Drawer>
  );
};

export default CustomDrawer;
