import { SVGProps } from "react";

export function DeleteIcon({
  width = 16,
  height = 16,
  viewBox = "0 0 16 16",
  color = "#F04438",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <g clip-path="url(#clip0_2478_7837)">
        <path
          d="M10 6.00065L6 10.0007M6 6.00065L10 10.0007M14.6667 8.00065C14.6667 11.6826 11.6819 14.6673 8 14.6673C4.3181 14.6673 1.33333 11.6826 1.33333 8.00065C1.33333 4.31875 4.3181 1.33398 8 1.33398C11.6819 1.33398 14.6667 4.31875 14.6667 8.00065Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2478_7837">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function TrashIcon({
  width = 16,
  height = 16,
  viewBox = "0 0 16 16",
  stroke = "#344054",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M2 4.00004H3.33333M3.33333 4.00004H14M3.33333 4.00004V13.3334C3.33333 13.687 3.47381 14.0261 3.72386 14.2762C3.97391 14.5262 4.31304 14.6667 4.66667 14.6667H11.3333C11.687 14.6667 12.0261 14.5262 12.2761 14.2762C12.5262 14.0261 12.6667 13.687 12.6667 13.3334V4.00004H3.33333ZM5.33333 4.00004V2.66671C5.33333 2.31309 5.47381 1.97395 5.72386 1.7239C5.97391 1.47385 6.31304 1.33337 6.66667 1.33337H9.33333C9.68696 1.33337 10.0261 1.47385 10.2761 1.7239C10.5262 1.97395 10.6667 2.31309 10.6667 2.66671V4.00004"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

