import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  autoComplete: {
    "& .MuiAutocomplete-endAdornment": { display: "none" },
    "& .MuiInputBase-root": { paddingRight: "0 !important" },
    '& .MuiInputBase-root': {
      padding: '8px 10px !important'
    }
  },
}))