import RoundInputWithLabel from "components/RoundInputWithLabel";
import React from "react";
import {NumericFormat} from 'react-number-format';

const NumberTextField = (props) => {
  return (
    <NumericFormat
      required
      focused
      thousandSeparator={"."}
      decimalSeparator={","}
      customInput={RoundInputWithLabel}
      att={{
        '& label.Mui-focused': {
          color: props?.error ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)',
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            border: `1px solid ${props?.error ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'}`,
          },
        }
      }}
      {...props}
    />
  );
};

export default NumberTextField;
