import { Dialog } from "@mui/material";
import ErrorPopup from "components/ErrorPopup";
import { getConfigs } from "configs";
import { useState } from "react";

const configs = getConfigs();

const CustomFileInput = ({
  id = "upload-img-input",
  onChange,
  acceptFileTypes,
  style,
}) => {
  const [dialogConfigs, setDialogConfigs] = useState({
    show: false,
    content: "",
  });

  const handleCloseDialog = () => {
    setDialogConfigs({ ...dialogConfigs, show: false });
  };

  async function getDuration(file) {
    try {
      const url = URL.createObjectURL(file);
      return new Promise((resolve) => {
        const video = document.createElement("video");
        video.preload = "metadata";
        video.src = url;
        video.onloadedmetadata = function () {
          resolve(video.duration);
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleChangeImg = async (e) => {
    let firstFile = e.target.files[0];
    if (firstFile) {
      let duration = 0;
      let fileType = firstFile?.type;
      if (
        configs.ACCEPT_VIDEO_TYPES?.map((t) => `video/${t}`).indexOf(fileType) >
        -1
      ) {
        duration = await getDuration(firstFile);
      }
      // check duration of video
      const fileMb = firstFile?.size / 1024 ** 2;
      let isValidSize = fileMb < configs.MAX_FILE_SIZE;
      let isValidDuration = duration < configs.MAX_FILE_DURATION;

      if (isValidDuration && isValidSize) {
        onChange(e);
      } else {
        setDialogConfigs({
          show: true,
          content: !isValidSize
            ? `Vui lòng chọn file nhỏ hơn ${configs.MAX_FILE_SIZE}Mb.`
            : `Vui lòng chọn video dưới ${
                (configs.MAX_FILE_DURATION || 0) / 60
              } phút.`,
        });
      }
    }
  };

  return (
    <>
      <input
        type="file"
        id={id}
        onChange={handleChangeImg}
        accept={acceptFileTypes || configs?.ACCEPT_FILE_TYPES}
        style={style}
      />
      <Dialog open={dialogConfigs?.show} onClose={handleCloseDialog}>
        <ErrorPopup
          text={dialogConfigs?.content}
          onClickOut={handleCloseDialog}
        />
      </Dialog>
    </>
  );
};

export default CustomFileInput;
