const saveCart = (carts = []) => {
  localStorage.setItem("cart", JSON.stringify(carts));
};

const initialState = {
  cart: [],
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PUSH_ITEM": {
      let newCarts = [];
      action.payload?.map((attribute) => {
        if (attribute?.quantity > 0) {
          let findIndex = state?.cart?.findIndex(
            (item) =>
              item?.name === attribute?.name && item?.note === attribute?.note
          );
          if (findIndex >= 0) {
            let indexItem = state.cart[findIndex];
            state.cart[findIndex] = {
              ...indexItem,
              quantity: indexItem?.quantity + attribute?.quantity,
            };
          } else {
            newCarts.push(attribute);
          }
        }
        return attribute;
      });

      saveCart([...state.cart, ...newCarts]);
      return {
        ...state,
        cart: [...state.cart, ...newCarts],
      };
    }

    case "UPDATE_SAME_ITEM":
      let newCarts = state?.cart
        ?.filter((item) => item?.item_id !== action.payload.item_id)
        ?.concat(action.payload?.items || []);
      return {
        ...state,
        carts: newCarts,
      };

    case "REPLACE_CART":
      return {
        ...state,
        cart: action.payload,
      };

    case "RESET_CART":
      return {
        ...state,
        cart: [],
      };

    case "REMOVE_ITEM": {
      let newCarts = state.cart.filter(
        (item) =>
          item?.name !== action.payload?.name ||
          item?.note !== action.payload.note
      );

      saveCart(newCarts);
      return {
        ...state,
        carts: newCarts,
      };
    }

    case "DESC_QTY": {
      console.log(action.payload)
      let newCarts = state.cart
        .map((item) => {
          if (
            item?.name === action.payload.name &&
            item?.note === action.payload?.note &&
            JSON.stringify(item?.toppings) === JSON.stringify(action.payload?.toppings)
          ) {
            return { ...item, quantity: item.quantity - 1 };
          }
          return item;
        })
        .filter((item) => item.quantity > 0);
      saveCart(newCarts);
      return {
        ...state,
        cart: newCarts,
      };
    }

    case "INSC_QTY": {
      // Check and update item
      let item_exists = false;
      let newCarts = state.cart.map((item) => {
        if (
          item.name === action.payload?.name &&
          item?.note === action.payload?.note &&
          JSON.stringify(item?.toppings) === JSON.stringify(action.payload?.toppings)
        ) {
          item_exists = true;
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });
      if (!item_exists) {
        newCarts.push({ ...action.payload, quantity: 1 });
      }
      saveCart(newCarts);
      return {
        ...state,
        cart: newCarts,
      };
    }

    default:
      return state;
  }
};

export default cartReducer;
