import React from "react";
import { Box, IconButton } from "@mui/material";
import addBtn from "assets/icons/add_btn.png";
import useStyles from "./styles";

const PlusButton = ({ onClick, att }) => {
  const classes = useStyles();
  return (
    <Box className={classes.box} sx={{...att}}>
      <IconButton onClick={onClick}>
        <img src={addBtn} alt="addBtn" height={56} />
      </IconButton>
    </Box>
  );
};

export default PlusButton;
