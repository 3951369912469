import { Stack, StackProps, styled } from "@mui/material";

const Row = (props: StackProps) => {
  return <Stack spacing={2} direction="row" alignItems="center" {...props} />;
};

export const InputRow = (props: StackProps) => (
  <StyledInputRow className="input-row" {...props} />
);

const StyledInputRow = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 16,
  ".input-label": {
    // width: 200,
  },
  ".input, .quill": {
    flex: 1,
  },
  ".media-input": {
    display: "none",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 8,
    ".input, .quill": {
      width: "100%",
    },
  },
}));

export default Row;
