import React from "react";
import { Box } from "@mui/material";

import scanToOrderIcon from "assets/images/manage_qr/scan_to_order.svg";
import expIcon from "assets/icons/circle_neg.svg";
import useStyles from "./styles";

const QrImage = ({ is_expired, qrImg, maxWidth, att }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.qrImgBox}
      p={1}
      pb={0}
      sx={{ maxWidth: maxWidth, borderRadius: 2, ...att }}
    >
      {is_expired && (
        <Box className="expBox">
          <img src={expIcon} alt="icon" />
        </Box>
      )}
      <img src={qrImg} className="qrImg" alt="img" />
      <img src={scanToOrderIcon} alt="icon" className="scan" />
    </Box>
  );
};

export default QrImage;
