import { Typography } from "@mui/material";
import React from "react";

const Title = (props) => {
  const { text, fontSize = 18, att, className, onClick, leftIcon } = props;
  const styles = {
    fontWeight: 600,
    ...att,
  };

  return (
    <Typography
      className={className}
      sx={{
        fontSize: fontSize,
        ...styles,
      }}
      onClick={onClick}
    >
      {leftIcon}
      {text}
    </Typography>
  );
};

export default Title;
