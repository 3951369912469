import { Box, Stack, styled, Typography } from "@mui/material";
import { Img } from "./Img";
import { CompanyType } from "types/common";
import { forwardRef } from "react";
import { StyledA, StyledATag, Text, XXsText, XsText, XsTextMd } from "./Text";
import CountryFlag from "./CountryFlag";
// import { SendInquiryButton } from "./Button";
import { useIntl } from "react-intl";
import { formatImg } from "helpers/format";
import { CrownTooltip, LevelTooltip, Tooltip, VerifyTooltip } from "./Tooltip";
import { SOURCING_DOMAIN } from "constants/schemas";
import TimeDiff from "./TimeDiff";
import dayjs from "dayjs";
import Row from "./Row";
import { Email2Icon, PhoneIcon, RightArrowIcon } from "assets/icons";
import { SendMessageButton } from "./Button";
import { RunningText } from "./AnimationText";
import { DIRECT_PATHS } from "constants/routes";

type Props = {
  company?: CompanyType;
  showfullflag?: boolean;
};

const Company = forwardRef(({ company, showfullflag }: Props, ref: any) => {
  const intl = useIntl();
  const now = dayjs();

  const {
    is_featured,
    country,
    country_code,
    slug,
    company_logo,
    verified,
    categories,
    num_posted_rfq = 0,
    company_name,
    business_types,
    verification_level,
    is_seen,
    last_active_datetime,
    is_verified_email,
    is_verified_phone,
    chat_available,
    identity,
    contact,
    rfq_title,
    last_updated,
    rfq_image,
    rfq_id,
  } = company || {};

  return (
    <StyledATag href={SOURCING_DOMAIN + `/${slug}`}>
      <StyledCompany ref={ref} className={is_seen ? "is-seen-box" : ""}>
        <Stack className="info_box" spacing={1}>
          <Stack direction="row" spacing={1} height={30}>
            {company_logo && (
              <Img
                src={formatImg(company_logo, 3)}
                alt="img"
                className="image"
              />
            )}

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={0.75}
            >
              <StyledA>
                <XsTextMd className="cutoff-text oneline">
                  {company_name}
                </XsTextMd>
              </StyledA>
              {is_featured === 1 && (
                <CrownTooltip
                  title={intl.formatMessage({
                    id: "this_is_a_premium_buyer",
                  })}
                />
              )}
              {verified === 1 && (
                <VerifyTooltip
                  title={intl.formatMessage({
                    id: "supplier_is_verified_by_freshdi",
                  })}
                />
              )}
              {verification_level && (
                <VerifyTooltip
                  title={
                    <LevelTooltip
                      level={verification_level}
                      title={intl.formatMessage({
                        id: "buyer_is_verified_by_freshdi",
                      })}
                    />
                  }
                />
              )}
            </Stack>
          </Stack>

          <Stack direction="row" spacing={1} minHeight={24}>
            <CountryFlag
              {...{ country, country_code }}
              className="cutoff-text oneline"
              style={{
                maxWidth: showfullflag ? "auto" : 84,
                display: "-webkit-box",
              }}
            />
            {num_posted_rfq > 0 && (
              <XsTextMd className="rfq-posted ellipsis-text">
                {intl.formatMessage(
                  { id: "qlt_rfq_posted" },
                  { quantity: num_posted_rfq }
                )}
              </XsTextMd>
            )}
          </Stack>

          <XsText className="cutoff-text oneline categories">
            {categories && intl.formatMessage({ id: "products" }) + ": "}

            {categories}
          </XsText>

          {business_types && (
            <XsText className="cutoff-text oneline">
              {intl.formatMessage({ id: "business_types" }) + ": "}
              {business_types}
            </XsText>
          )}

          {/* active time  */}
          <XXsText className="active-time ellipsis-text">
            {last_active_datetime &&
              intl.formatMessage(
                { id: "active_time_at" },
                {
                  time: (
                    <TimeDiff
                      startTime={dayjs(last_active_datetime).format()}
                      endTime={dayjs().format()}
                    />
                  ),
                }
              )}
          </XXsText>

          {chat_available === 1 && <SendMessageButton identity={identity} />}

          {chat_available !== 1 && (
            <Row pt={1} justifyContent="space-between" height={30}>
              <Row>
                {is_verified_phone === 1 && (
                  <Tooltip
                    title={intl.formatMessage({
                      id: "buyer_prefers_to_contact_via_whatsApp",
                    })}
                  >
                    <div className="app-icon">
                      <PhoneIcon />
                      <Text className="prefer">
                        {intl.formatMessage({ id: "prefer" })}
                      </Text>
                    </div>
                  </Tooltip>
                )}

                {is_verified_email === 1 && (
                  <Tooltip
                    title={intl.formatMessage({
                      id: "buyer_prefers_to_contact_via_email",
                    })}
                  >
                    <div className="app-icon">
                      <Email2Icon />
                      <Text className="prefer">
                        {intl.formatMessage({ id: "prefer" })}
                      </Text>
                    </div>
                  </Tooltip>
                )}
                {contact && (
                  <XsText className="contact-text ellipsis-text">
                    {contact}
                  </XsText>
                )}
              </Row>
            </Row>
          )}

          {rfq_title ? (
            <StyledATag href={DIRECT_PATHS.rfqDetail(rfq_id || "", true)}>
              <Row className="post-box" p={0.5} spacing={0.5}>
                {rfq_image && (
                  <Img
                    src={formatImg(rfq_image, 1)}
                    alt="img"
                    className="object-cover post-img"
                  />
                )}
                <Stack
                  flex={1}
                  maxWidth={`calc(100% - ${rfq_image ? "50" : "24"}px)`}
                >
                  <StyledA>
                    <RunningText className="post-title" timer={15}>
                      {rfq_title}
                    </RunningText>
                  </StyledA>
                  <Typography className="post-time">
                    <TimeDiff
                      startTime={dayjs(last_updated).format()}
                      endTime={now.format()}
                    />
                    {intl.formatMessage({ id: "ago" })}
                  </Typography>
                </Stack>
                <div className="post-svg">
                  <RightArrowIcon />
                </div>
              </Row>
            </StyledATag>
          ) : (
            <Box height={37} />
          )}
        </Stack>
      </StyledCompany>
    </StyledATag>
  );
});

const StyledCompany = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  overflow: "hidden",
  position: "relative",
  backgroundColor: "#fff",
  border: "1px solid rgba(0, 0, 0, 0.15)",
  cursor: "pointer",
  height: "100%",
  width: "100%",
  "& .medias": {
    height: 182,
    img: {
      width: "100%",
      height: "100%",
    },
  },
  // height: 341,
  "& .image": {
    height: 30,
    width: 30,
    objectFit: "cover",
  },
  "& .info_box": {
    padding: 8,
    ".rfq-posted": {
      color: "#C4320A",
      backgroundColor: "#FFF6ED",
      borderRadius: 16,
      padding: "2px 8px",
    },
    ".country_flag img": {
      marginRight: 4,
    },
    "& .sponsored": {
      backgroundColor: "#fff",
      padding: 4,
    },
    "& .verified_icon": {
      padding: 4,
      border: "1px solid #EAECF0",
      borderRadius: 6,
      height: 24,
      boxSizing: "border-box",
    },
    "& .address": {
      color: "#9D9D9D",
    },
    "& .avatar": {
      width: 64,
      height: 64,
      objectFit: "cover",
      borderRadius: "50%",
    },
    ".active-time": {
      color: "var(--sub-color)",
      height: 15,
      fontStyle: "italic",
    },
  },
  ".post-box": {
    borderRadius: 4,
    border: "0.5px solid var(--gray-300)",
    cursor: "pointer",
    img: {
      width: 24,
      height: 24,
    },
    ".post-title": {
      fontSize: 10,
      color: "var(--gray-800)",
    },
    ".post-time": {
      fontSize: 8,
      color: "var(--gray-600)",
      fontStyle: "italic",
    },
    ".post-svg": {
      padding: "0 6px",
      cursor: "pointer",
    },
  },
  "& .trust_icon": {
    position: "absolute",
    top: 0,
    right: 0,
    width: 40,
  },
  "& .categories": {
    color: "var(--gray)",
    minHeight: 18,
  },
  ".infos-text": {
    color: "var(--gray)",
  },
  "& .send-inquiry-btn": {
    fontSize: "calc(100% * 0.85)",
    padding: 6,
  },
  ".contact-text": {
    color: "var(--gray-500)",
    maxWidth: 132,
  },
  ".send-msg-btn": {
    padding: 3,
  },

  [theme.breakpoints.down("md")]: {
    // height: 244,
    "& .image": {
      // height: 124,
    },
  },
}));

export default Company;
