import { createContext, useContext, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import enTranslation from "lang/en.json";
import viTranslation from "lang/vi.json";
import { useSelector } from "react-redux";
import { userSelector } from "services/redux/selectors/userSelector";
import { langStorage } from "helpers/localStorage";
import { getBrowserLanguage } from "helpers/browser";
// import { getUser } from "redux/selectors/user";

const translationMessages: any = {
  en: enTranslation,
  vi: viTranslation,
};

const LanguageContext = createContext<{
  language: "en" | "vi";
  setLanguage: any;
}>({
  language: "vi",
  setLanguage: (value: string) => {},
});

export const useLanguageContext = () => useContext(LanguageContext);

const LanguageProvider = ({ children, ...restProps }: any) => {
  const user = useSelector(userSelector);
  const [currentLanguage, setCurrentLanguage] = useState<"en" | "vi">("en");

  useEffect(() => {
    setCurrentLanguage(
      user?.language || langStorage.get("selected") || getBrowserLanguage()
    );
  }, [navigator.language, user]);

  return (
    <IntlProvider
      {...restProps}
      locale={currentLanguage}
      messages={translationMessages[currentLanguage]}
      defaultLocale={"en"}
    >
      <LanguageContext.Provider
        value={{ language: currentLanguage, setLanguage: setCurrentLanguage }}
      >
        {children}
      </LanguageContext.Provider>
    </IntlProvider>
  );
};

export default LanguageProvider;
