import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({

  searchBox: {
    backgroundColor: '#F2F2F2',
    borderRadius: 4,
    width: '100%',
  },
  searchContent: {
    display: 'flex',
    alignItems: 'center',
    padding: 4,
    '& svg': {
      fontSize: 20,
      opacity: 0.7
    },
    '& .MuiInputBase-root': {
      width: '100%'
    }

  }
}))