const initialState = {
  selectedCustomer: null,
  customerConfigs: {},
  customerFilterConfigs: {},
  refreshCustomer: false,
  customerParams: {}
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_SELECTED_CUSTOMER": {
      return {
        ...state,
        selectedCustomer: action.payload,
      };
    }

    case "CHANGE_CUSTOMER_PARAMS": {
      return {
        ...state,
        customerParams: action.payload,
      };
    }

    case "REFRESH_CUSTOMER": {
      return {
        ...state,
        refreshCustomer: !state?.refreshCustomer,
      };
    }

    case "CHANGE_CUSTOMER_CONFIGS": {
      return {
        ...state,
        customerConfigs: action?.payload,
      };
    }

    case "CHANGE_CUSTOMER_FILTER_CONFIGS": {
      return {
        ...state,
        customerFilterConfigs: action?.payload,
      };
    }

    default:
      return state;
  }
};

export default customerReducer;
