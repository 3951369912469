const initialState = {
  selectedItem: null,
  selectedBatch: null,
  refreshItem: false,
  refreshBatch: false,
  refreshItems: false,
  isPreviewing: false,
  isNew: false,
  pressingParams: {},
  itemParams: {},
  selectedStepIdx: null,
  itemDetail: {}
};

const itemReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_IS_NEW": {
      return {
        ...state,
        isNew: action.payload,
      };
    }
    
    case "SET_SELECTED_ITEM": {
      return {
        ...state,
        selectedItem: action.payload,
      };
    }

    case "SET_SELECTED_BATCH": {
      return {
        ...state,
        selectedBatch: action.payload,
      };
    }

    case "REFRESH_ITEM": {
      return {
        ...state,
        refreshItem: !state?.refreshItem,
      };
    }

    case "SET_ITEM_DETAIL": {
      return {
        ...state,
        itemDetail: action.payload
      };
    }

    case "SET_PRESSING_PARAMS": {
      return {
        ...state,
        pressingParams: action.payload
      };
    }

    case "SET_ITEM_PARAMS": {
      return {
        ...state,
        itemParams: action.payload
      };
    }

    case "REFRESH_ITEMS": {
      return {
        ...state,
        refreshItems: !state?.refreshItems,
      };
    }

    case "CHANGE_IS_PREVIEWING": {
      return {
        ...state,
        isPreviewing: action.payload,
      };
    }

    case "REFRESH_BATCH": {
      return {
        ...state,
        refreshBatch: !state?.refreshBatch,
      };
    }

    case "CHANGE_SELECTED_STEP_IDX": {
      return {
        ...state,
        selectedStepIdx: action.payload,
      };
    }

    default:
      return state;
  }
};

export default itemReducer;
