import { BrowserRouter as Router } from "react-router-dom";
import { withSeparateApp } from "hocs/withSeparateApp";
import AppProvider from "providers/app";

const AppRoutes = () => {
  return (
    <Router>
      <AppProvider>{withSeparateApp()}</AppProvider>
    </Router>
  );
};

export default AppRoutes;
