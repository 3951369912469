import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  priceBox: {
    '& .itemName': {
      color: '#4F4F4F',
      cursor: 'pointer',
      fontSize: '14px',
      // fontWeight: 600
    },
    '& .itemPrice': {
      color: '#EB5757',
      fontWeight: 600,
      fontSize: '14px',
      marginRight: 8,
    },
    '& .originPrice': {
      color: '#828282',
      textDecorationLine: 'line-through',
      fontWeight: 100,
      fontSize: 12
    },
    '& .attribute': {
      fontWeight: 14,
      color: '#828282',
    },
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
}))