export const getConfigs = () => {
  let checkIsPro = process.env.REACT_APP_ENV === "production";
  let checkIsLocal = process.env.REACT_APP_ENVIRONMENT === "local";

  let domain = checkIsPro
    ? "https://svc.freshdi.com"
    : checkIsLocal
    ? "http://194.233.75.118:8000"
    : "https://rpc.freshdi.com";

  let ghnDomain = checkIsPro
    ? "https://online-gateway.ghn.vn"
    : "https://dev-online-gateway.ghn.vn";

  let sidInUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("token");
  };

  let authorToken = sidInUrl() || null;

  let webSocketDomain = `wss://${
    checkIsPro ? "freshdi.com" : "tr.freshdi.com"
  }`;

  let traceDomain = `https://${checkIsPro ? "freshdi.com" : "tr.freshdi.com"}`;

  let imgServer = `https://${checkIsPro ? "img" : "pan"}.freshdi.com`;

  let firebaseConfigs = {
    apiKey: "AIzaSyD-7GuEXauSxBRd8sdgRcakezIUP8SEtKo",
    authDomain: "freshdi-89fc3.firebaseapp.com",
    projectId: "freshdi-89fc3",
    storageBucket: "freshdi-89fc3.appspot.com",
    messagingSenderId: "851446202599",
    appId: "1:851446202599:web:a1121773dfa3c02a402242",
    measurementId: "G-ZDT6T1FHZB",
  };

  // SETTING FILE
  const acceptFileTypes = "image/*,audio/*,video/*";
  const videoTypes = ["wmv", "avi", "mp4", "mkv"];
  const imageTypes = ["png", "jpeg", "jpg", "webp", "heic"];
  const maxFileDuration = 120;
  const maxFileSize = 50;

  // RETURN
  return {
    SERVER_API_DOMAIN: `${domain}/api/method`,
    GHN_API_DOMAIN: ghnDomain,
    SERVER_IMAGE_URL: `${domain}`,
    TOKEN: authorToken ? `token ${authorToken}` : null,
    WEBSOCKET_SERVER_URL: {
      wssOrder: `${webSocketDomain}/wss/wss_order_web`,
      wssTable: `${webSocketDomain}/wss/wss_table`,
    },
    TRACE_DOMAIN: traceDomain,
    IMG_SERVER_URL: imgServer,
    FIREBASE: firebaseConfigs,
    VERIFY_FB: process.env.REACT_APP_VAPIDKEY_FIREBASE,

    ACCEPT_FILE_TYPES: acceptFileTypes,
    MAX_FILE_SIZE: maxFileSize,
    MAX_FILE_DURATION: maxFileDuration,
    ACCEPT_VIDEO_TYPES: videoTypes,
    ACCEPT_IMAGE_TYPES: imageTypes,
    GM_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API,
  };
};
