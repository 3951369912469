import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),

    body1: {
      fontSize: 16,
      "@media (min-width: 600px)": {},
    },
    body2: {
      fontSize: 14,
      "@media (min-width: 600px)": {},
    },
  },
  palette: {
    primary: {
      main: "#008934",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          color: "initial"
        },
      },
    },
  },
});
