import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { Typography, styled } from "@mui/material";

const DOMAddressType = ({ addressType, addressTypeLabel }) => {
  const getTextAddressType = () => {
    switch (addressType) {
      case 0:
        return "Nhà riêng";
      case 1:
        return "Văn phòng";
      case 2:
        return addressTypeLabel || "Khác";
      default:
        return;
    }
  };

  const getIconAddressType = () => {
    switch (addressType) {
      case 0:
        return <HomeOutlinedIcon />;
      case 1:
        return <BusinessCenterOutlinedIcon />;
      case 2:
        return <BookmarkBorderOutlinedIcon />;
      default:
        return;
    }
  };

  return (
    <AddressTypeText>
      {getIconAddressType()}
      {getTextAddressType()}
    </AddressTypeText>
  );
};

const AddressTypeText = styled((props) => (
  <Typography variant="body2" fontWeight={600} {...props} />
))({
  display: "flex",
  alignItems: "center",
  "& svg": {
    marginRight: 8,
  },
});

export default DOMAddressType;
