import http from "./http";

const V3_ENDPOINT = "/api/method/freshdi.api.web.v3";

export const apiLoginWithGg = (data: any) =>
  http.post(`${V3_ENDPOINT}.farm.login_via_google`, data);

export const apiGetProfile = () => http.post(`${V3_ENDPOINT}.supplier.me`);

export const apiGetSettingProfile = () =>
  http.post(`${V3_ENDPOINT}.supplier.get_profile`);

export const apiGetUserInformation = (token?: string) =>
  http.get(`${V3_ENDPOINT}.customer.get_profile`, { params: { token } });

export const apiCheckUserExist = (data: any) =>
  http.post(`${V3_ENDPOINT}.customer.check_phone_exist`, data);

export const apiUpdateUser = (data: any) =>
  http.post(`${V3_ENDPOINT}.supplier.update_profile`, data);

export const apiRegister = (data: any) =>
  http.post(`${V3_ENDPOINT}.auth.signup`, data);

export const apiUpdateSettingUser = (data: any) =>
  http.post(`${V3_ENDPOINT}.supplier.update_profile`, data);

export const apiSignupWithGg = (data: any) =>
  http.post(`${V3_ENDPOINT}.farm.sign_up`, data);

export const apiLogoutUser = () => http.get(`${V3_ENDPOINT}.customer.logout`);

export const apiGetUserBalance = () =>
  http.get(`${V3_ENDPOINT}.wallet.get_farm_wallet`);

export const apiRequestVetification = (data: {
  email: string;
  doc_file: string;
}) => http.post(`${V3_ENDPOINT}.supplier.request_verification`, data);

export const apiActiveAccount = (data: any) =>
  http.post(`${V3_ENDPOINT}.auth.activate_account`, data);
