const initialState = {
  agents: [],
  selectedAgent: null,
  reloadAgentDetail: false,
  reloadAgents: false
};

const agentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_AGENT": {
      return {
        ...state,
        selectedAgent: action.payload,
      };
    }

    case "RELOAD_AGENT_DETAIL": {
      return {
        ...state,
        reloadAgentDetail: !state.reloadAgentDetail,
      };
    }

    case "RELOAD_AGENTS": {
      return {
        ...state,
        reloadAgents: !state.reloadAgents,
      };
    }
    
    case "CHANGE_AGENTS": {
      return {
        ...state,
        agents: action.payload,
      };
    }

    default:
      return state;
  }
};

export default agentReducer;
