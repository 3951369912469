import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
    background: {
        top: 0,
        width: "100%",
        height: "100%",
        position: `absolute`,
        zIndex: 1400,
        backgroundColor: "rgba(255,255,255,0.7)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    popupBox :{
        position: "fixed",
        background: "#00000050",
        width: "100%",
        height: "100vh",
        top: 0,
        left: 0,
        zIndex: 1395
      },
       
      box: {
        position: "absolute",
        bottom: "50%",
        left: "50%",
        transform: "translate(-50%, 50%)",
        width: "50%",
        margin: "0 auto",
        height: "auto",
        padding: "10px",
        overflow: "auto",
        textAlign: "center",
      },
}))