import { Stack, styled } from "@mui/material";
import {
  AcceptButton,
  ConfirmButton,
  OpenTabLink,
  Row,
  SmText,
  TextButton,
  TextSemibold,
} from "components";
import { PATHS } from "constants/routes";
import useAuth from "hooks/useAuth";
import { useLanguageContext } from "providers/mul-language";
import { ReactNode, useMemo } from "react";
import { useIntl } from "react-intl";
import { BlockType } from "types/common";

const Blocker = ({ is_blocked, required }: BlockType) => {
  const intl = useIntl();
  const { onDirectLogin, onDirectSignup } = useAuth();
  const { language } = useLanguageContext();

  const onClickBecome = () => {
    window.location.href = PATHS.supplierPricing;
  };

  const blockTitle = useMemo(() => {
    return required === "login"
      ? "sign_in_sign_up_to_continue"
      : required === "premium"
      ? "buyers_blocker_desc"
      : required === "verified"
      ? "verified_to_all_results"
      : "";
  }, [required, language]);

  const BlockContent = useMemo(() => {
    switch (required) {
      case "login":
        return (
          <Row justifyContent="center">
            <AcceptButton onClick={onDirectSignup}>
              {intl.formatMessage({ id: "JoinForFree" })}
            </AcceptButton>
            <TextButton onClick={onDirectLogin}>
              {intl.formatMessage({ id: "login" })}
            </TextButton>
          </Row>
        );
      case "premium":
        return (
          <ConfirmButton onClick={onClickBecome}>
            {intl.formatMessage({ id: "become_premium_membership" })}
          </ConfirmButton>
        );

      case "verified":
        return (
          <AcceptButton onClick={onClickBecome}>
            {intl.formatMessage({ id: "verify_to_continue" })}
          </AcceptButton>
        );

      default:
        return null;
    }
  }, [required, language]);

  if (!is_blocked) {
    return null;
  }

  return (
    <StyledBlocker spacing={2} alignItems="center">
      {/*<Img src={RED_WARNING_ICON} alt="icon" className="warning-icon" />*/}
      <TextSemibold>
        {blockTitle &&
          intl.formatMessage({
            id: blockTitle,
          })}
      </TextSemibold>

      {BlockContent}
    </StyledBlocker>
  );
};

export const ChatMessageBlocker = ({
  text = "can_only_take_send_message_when_buyer_responds",
}: {
  text?: string;
}) => {
  const t = useIntl();
  return (
    <StyledChatMessageBlocker spacing={1}>
      <SmText>
        {t.formatMessage({
          id: text,
        })}
      </SmText>
      <SmText>
        {t.formatMessage(
          { id: "become_to_premium_embership_send_message_now" },
          {
            a: (text: ReactNode) => (
              <OpenTabLink href={PATHS.supplierPricing}>{text}</OpenTabLink>
            ),
          }
        )}
      </SmText>
    </StyledChatMessageBlocker>
  );
};

const StyledBlocker = styled(Stack)({
  textAlign: "center",
  paddingBottom: 32,
  borderRadius: 16,
  width: "100%",
  ".warning-icon": {
    width: 54,
    margin: "0 auto",
  },
  button: {
    maxWidth: 248,
  },
});

const StyledChatMessageBlocker = styled(Stack)({
  p: {
    color: "var(--gray)",
    a: {
      color: "var(--color)",
      fontWeight: 500,
      textDecoration: "underline",
    },
  },
});

export default Blocker;
