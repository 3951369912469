import { Box } from "@mui/material";
import React from "react";

import useStyles from "./styles";

const HorList = (props) => {
  const classes = useStyles();
  const { items, att, ...otherProps } = props;

  return (
    <Box className={classes.horList} sx={{ ...att }} {...otherProps}>
      {items}
    </Box>
  );
};

export default HorList;
