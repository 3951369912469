import { Box, Stack, Typography, styled } from "@mui/material";
import { GoDotFill } from "react-icons/go";
import { useIntl } from "react-intl";
import { Img } from "./Img";
import { getCountryFlag } from "helpers/address";
import { memo, useMemo } from "react";
import { SmText, XsText, XsTextMd } from "./Text";
import { convertToFullMonth, getCurrentDateZone } from "helpers/datetime";
import { useLanguageContext } from "providers/mul-language";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { YELLOW_CROWN } from "assets/icons";

type Props = {
  data?: {
    customer?: string;
    is_verified?: number;
    fd_country?: string;
    joined_time?: string;
    num_posted_rfq?: number;
    country_code?: string;
    is_featured?: number;
  };
};

const RFQCompanyBox = ({ data }: Props) => {
  const { language } = useLanguageContext();
  const { downTabletBreakpoint: isMobile } = useMediaBreakpoints();
  const intl = useIntl();
  const {
    customer,
    is_verified,
    fd_country,
    country_code,
    joined_time,
    num_posted_rfq = 0,
    is_featured = 0,
  } = data || {};

  return (
    <StyledRFQCompanyBox flex={1} alignItems={"center"}>
      <Typography className="user_name">{customer}</Typography>
      {joined_time && (
        <SmText className="join-time">
          {intl.formatMessage(
            { id: "join_time" },
            {
              time: convertToFullMonth(
                joined_time,
                getCurrentDateZone(language)
              ),
            }
          )}
        </SmText>
      )}

      <Stack direction="row" spacing={1} alignItems="center" mt={1}>
        {num_posted_rfq > 0 && (
          <XsTextMd className="num-posted">
            {intl.formatMessage(
              { id: "qlt_rfq_posted" },
              { quantity: num_posted_rfq }
            )}
          </XsTextMd>
        )}
        {is_featured === 1 && (
          <XsText className="sponsored-label">
            <Img src={YELLOW_CROWN} alt="icon" />
            {!isMobile && intl.formatMessage({ id: "premium" })}
          </XsText>
        )}
        <CompanyVerify is_verified={is_verified} />
        <CompanyFlag {...{ fd_country, country_code }} />
      </Stack>
    </StyledRFQCompanyBox>
  );
};

export const CompanyVerify = ({ is_verified = 0 }) => {
  const intl = useIntl();
  const verifyConfigs = useMemo(() => {
    switch (is_verified) {
      case 1:
        return {
          label: intl.formatMessage({ id: "email_verified" }),
          color: "#027B30",
          bg: "#E3FFEE",
        };
      case 0:
        return {
          label: intl.formatMessage({ id: "email_verifying" }),
          color: "#344054",
          bg: "#F2F4F7",
        };
      default:
        break;
    }
  }, [is_verified, intl]);

  return (
    <StyledCompanyVerify
      bgsolor={verifyConfigs?.bg}
      scolor={verifyConfigs?.color}
    >
      <GoDotFill />
      {verifyConfigs?.label}
    </StyledCompanyVerify>
  );
};

const StyledCompanyVerify = styled(Typography)<{
  bgsolor?: string;
  scolor?: string;
}>(({ bgsolor, scolor }) => ({
  padding: "4px 10px 4px 6px",
  borderRadius: 16,
  backgroundColor: bgsolor,
  color: scolor,
  lineHeight: 1.25,
  fontSize: 12,
  fontWeight: 500,
  width: "fit-content",
  display: "flex",
  alignItems: "center",
  gap: 6,
}));

export const CompanyFlag = ({
  fd_country,
  country_code,
}: {
  fd_country?: string;
  country_code?: string;
}) => {

  if (!fd_country) return null;

  return (
    <StyledCompanyFlag className="email_status">
      {country_code && (
        <Img src={getCountryFlag(country_code).src} alt="flag" />
      )}
      {fd_country}
    </StyledCompanyFlag>
  );
};

const StyledCompanyFlag = styled(Typography)({
  fontSize: 12,
  fontWeight: 500,
  width: "fit-content",
  display: "flex",
  alignItems: "center",
  gap: 6,
  padding: "3px 10px",
  borderRadius: 32,
  border: "0.5px solid #EAECF0",
  img: {
    width: 12,
    height: 9,
    borderRadius: 8,
  },
});

const StyledRFQCompanyBox = styled(Box)({
  ".user_name": {
    fontWeight: 600,
  },
  ".join-time": {
    color: "var(--gray-color)",
  },
  ".num-posted": {
    color: "#C4320A",
    backgroundColor: "#FFF6ED",
    borderRadius: 16,
    padding: "2px 8px",
  },
});

export default memo(RFQCompanyBox);
