import React from "react";
import { Box, Typography, Divider } from "@mui/material";

import useStyles from "./styles";
import Loading from "components/Loading";

const SearchPeople = ({
  showDropdown,
  loading,
  searchCustomers,
  handleClickCustomer,
}) => {
  const classes = useStyles();

  return (
    <>
      {showDropdown && (
        <Box className={classes.selectCustomerBos} mt={5}>
          <Box className={classes.selectCustomerContent} py={1}>
            {loading && <Loading circle showText={false} />}
            {!loading &&
              searchCustomers?.map((customer, index) => (
                <>
                  <Box
                    key={index}
                    onClick={() => handleClickCustomer(customer)}
                    className="customerBox"
                    py={1}
                    px={2}
                  >
                    <Typography>
                      {customer?.customer_phone} - {customer?.customer_name}
                    </Typography>
                  </Box>
                  {index < searchCustomers?.length - 1 && <Divider />}
                </>
              ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default SearchPeople;
