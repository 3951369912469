import { Box, BoxProps, Dialog, Stack, styled } from "@mui/material";
import { LockIcon, UnLockIcon } from "assets/icons";
import { ConfirmButton, OpenTabLink, TextMd } from "components";
import { PATHS } from "constants/routes";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useIntl } from "react-intl";

const Lock = (props: BoxProps & { title?: string }) => {
  const {
    title = "to_unlock_premium_supplier_upgrade_your_plan_now",
    ...rest
  } = props;
  const [showDialog, setShowDialog] = useState(false);
  const t = useIntl();

  const onCloseDialog = () => setShowDialog(false);

  const onOpenDialog = () => setShowDialog(true);

  return (
    <StyledLock {...rest}>
      <div className="lock-box">
        <LockIcon onClick={onOpenDialog} />
      </div>

      <Dialog open={showDialog} onClose={onCloseDialog}>
        <StyledUnlock spacing={2}>
          <Stack direction="row" spacing={1.5}>
            <UnLockIcon style={{ marginTop: 8 }} className="unluck-icon" />
            <TextMd>
              {t.formatMessage({
                id: title,
              })}
            </TextMd>
            <IoMdClose className="close-icon" onClick={onCloseDialog} />
          </Stack>
          <div className="btn-box">
            <OpenTabLink href={PATHS.supplierPricing}>
              <ConfirmButton>
                {t.formatMessage({ id: "upgrade_now" })}
              </ConfirmButton>
            </OpenTabLink>
          </div>
        </StyledUnlock>
      </Dialog>
    </StyledLock>
  );
};

const StyledLock = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ".lock-box": {
    padding: "8px 9px",
    borderRadius: 8,
    border: "1px solid #EAECF0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 1px 2px 0px #1018280D",
    cursor: "pointer",
    svg: {},
  },
});

const StyledUnlock = styled(Stack)({
  borderRadius: 12,
  padding: 16,
  maxWidth: 380,
  ".btn-box": {
    textAlign: "center",
    button: {
      maxWidth: 123,
      margin: "0 auto",
    },
  },
  ".close-icon": {
    minWidth: 22,
    height: 22,
    cursor: "pointer",
  },
  ".unluck-icon": {
    minWidth: 25,
  },
});

export default Lock;
