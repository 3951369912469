import React from "react";
import ReactPlayer from "react-player/lazy";

import { checkIsVideo } from "services/utils/helpers";
import useStyle from "./styles";

const DynamicMedia = ({ url, videoProps, onClick = () => {} }) => {
  const classes = useStyle();

  return (
    <>
      {checkIsVideo(url) ? (
        <ReactPlayer
          style={{ cursor: "pointer" }}
          url={url}
          width="100%"
          height="100%"
          controls
          {...videoProps}
          onClick={onClick}
        />
      ) : (
        <img className={classes.img} src={url} alt="img" onClick={onClick} />
      )}
    </>
  );
};

export default DynamicMedia;
