import { memo, useMemo, useState } from "react";
import { ConfirmButton, LgAcceptButton } from "./Button";
import { useIntl } from "react-intl";
import {
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  styled,
} from "@mui/material";
import { Input } from "./Input";
import { DpTextSemiBold, SmText, XsText } from "./Text";
import { Close } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import LogoFreshdi from "./FreshdiLogo";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useLoading } from "providers/loading";
import { useNotify } from "providers/notify";
import { apiGetBizCardRfq } from "services/api/rfq";
import { formatError } from "helpers/error";
import useGetData from "hooks/useGetData";
import { apiGetCountries } from "services/api/helpers";
import Menu from "./Menu";
import useAuth from "hooks/useAuth";
import { apiPurchaseContact } from "services/api/contact";
import { apiGetUserBalance } from "services/api/user";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "services/redux/actions/user";
import { userSelector } from "services/redux/selectors/userSelector";
// import BusinessCardOwned from "./BusinessCardOwned";

type Props = {
  type?: string;
  data?: any;
  onToggleReloadDetail?: () => void;
};

const GetBusinessCard = ({
  type = "rfq",
  data = {},
  onToggleReloadDetail = () => {},
}: Props) => {
  const { setLoading } = useLoading();
  const { setNotify } = useNotify();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const { isLoggedIn, currentBalance } = useAuth();
  const { is_owned_contact, contact_cost = 0, contact_id } = data;
  const [openCardDialog, setOpenCardDialog] = useState(false);
  const intl = useIntl();

  const onClickGetCard = () => {
    setLoading(true);
    apiPurchaseContact({ contact_id })
      .then((res) => {
        onToggleReloadDetail?.();

        apiGetUserBalance().then((res) => {
          dispatch(
            setUser({
              ...user,
              balance: {
                ...user?.balance,
                balance: res.data.message?.data?.balance,
              },
            })
          );
        });
      })
      .catch((err) => {
        setNotify({
          open: true,
          type: "error",
          msg: formatError(err)?.message,
        });
      })
      .finally(() => setLoading(false));
  };

  const onCloseCardDialog = () => setOpenCardDialog(false);

  const notEnoughCredit = useMemo(
    () => isLoggedIn && contact_cost > currentBalance && !is_owned_contact,
    [isLoggedIn, contact_cost, currentBalance, is_owned_contact]
  );

  return (
    <>
      {isLoggedIn && !contact_id && (
        <StyledLgAcceptButton
          className="get_card_button"
          onClick={onClickGetCard}
          disabled={!contact_id}
        >
          {intl.formatMessage({
            id: "not_available_get_biz",
          })}
          {isLoggedIn && !is_owned_contact && contact_cost > 0 && (
            <span className="cost">
              -{contact_cost} {intl.formatMessage({ id: "credit" })}
            </span>
          )}
        </StyledLgAcceptButton>
      )}

      {isLoggedIn && !is_owned_contact && contact_id && (
        <StyledLgAcceptButton
          className="get_card_button"
          onClick={onClickGetCard}
          disabled={notEnoughCredit}
        >
          {intl.formatMessage({
            id: "get_business_card",
          })}
          {isLoggedIn && !is_owned_contact && contact_cost > 0 && (
            <span className="cost">
              -{contact_cost} {intl.formatMessage({ id: "credit" })}
            </span>
          )}
        </StyledLgAcceptButton>
      )}

      {notEnoughCredit && (
        <XsText color="var(--error-color)" align="center">
          {intl.formatMessage({ id: "dont_enough_credits" })}
        </XsText>
      )}

      <StyledDialog open={openCardDialog} onClose={onCloseCardDialog}>
        {!isLoggedIn && <ContactForm {...{ onCloseCardDialog, type, data }} />}
      </StyledDialog>
    </>
  );
};

const ContactForm = ({
  onCloseCardDialog,
  type,
  data,
}: {
  onCloseCardDialog: () => void;
  type: string;
  data: any;
}) => {
  const { setLoading } = useLoading();
  const { setNotify } = useNotify();
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      customer_name: "",
      email: "",
      customer_phone: "",
      note: "",
    },
  });
  const { rfq_id } = data;
  const { data: countryData } = useGetData({
    promise: apiGetCountries,
  });
  const [selectedCountry, setSelectedCountry] = useState<any>();
  const countries = useMemo(() => {
    let newCountries = countryData?.data || [];
    if (newCountries.length > 0) {
      setSelectedCountry(newCountries[0]);
    }
    return newCountries;
  }, [countryData]);

  const onSubmitForm = (data: any) => {
    setLoading(true);

    data = {
      ...data,
      customer_phone: `+${selectedCountry?.custom_calling_code}${data.customer_phone}`,
    };
    const apiConfigs = {
      data: { ...data, rfq_id },
      api: apiGetBizCardRfq,
    };

    apiConfigs
      .api(apiConfigs.data)
      .then((res) => {
        setNotify({
          open: true,
          type: "success",
          msg: intl.formatMessage({ id: "sent" }),
        });
        onCloseCardDialog();
      })
      .catch((err) => {
        console.log(err);
        setNotify({
          open: true,
          type: "error",
          msg: formatError(err)?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <StyledContactForm p={3} spacing={3}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        className="header"
      >
        <div className="tmp" />
        <LogoFreshdi />
        <IconButton onClick={onCloseCardDialog}>
          <Close />
        </IconButton>
      </Stack>

      <DpTextSemiBold align="center">
        {intl.formatMessage({ id: "get_business_card" })}
      </DpTextSemiBold>

      <SmText className="reference-text">
        <IoMdInformationCircleOutline />
        {intl.formatMessage(
          { id: "can_reference" },
          {
            s: (code: any) => <span>{code}</span>,
          }
        )}
      </SmText>

      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={2}>
          <>
            <Grid item xs={12} md={6}>
              <Input
                placeholder={
                  "* " + intl.formatMessage({ id: "full_name_contact" })
                }
                {...register("customer_name", {
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      id: "Input.Error.requied",
                    }),
                  },
                })}
                fullWidth
                error={!!errors?.customer_name}
                helperText={errors?.customer_name?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                {...register("customer_phone", {
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      id: "Input.Error.requied",
                    }),
                  },
                })}
                placeholder={"* " + intl.formatMessage({ id: "phone_number" })}
                error={!!errors?.customer_phone}
                helperText={errors?.customer_phone?.message}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="choose-country">
                      <Menu
                        value={selectedCountry}
                        buttonText={
                          selectedCountry
                            ? `${selectedCountry.code} (+${selectedCountry.custom_calling_code})`.toUpperCase()
                            : ""
                        }
                      >
                        {countries.map((data: any) => {
                          let { name, country_name } = data;

                          return (
                            <MenuItem
                              key={name}
                              selected={selectedCountry == data}
                              onClick={() => setSelectedCountry(data)}
                            >
                              {country_name}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Input
                placeholder={"* " + intl.formatMessage({ id: "email_address" })}
                {...register("email", {
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      id: "Input.Error.requied",
                    }),
                  },
                })}
                fullWidth
                error={!!errors?.email}
                helperText={errors?.email?.message}
              />
            </Grid>
          </>

          <Grid item xs={12}>
            <Input
              placeholder={intl.formatMessage({ id: "enter_your_note" })}
              {...register("note")}
              multiline
              rows={5}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <ConfirmButton type="submit">
              {intl.formatMessage({ id: "submit" })}
            </ConfirmButton>
          </Grid>
        </Grid>
      </form>
    </StyledContactForm>
  );
};

const StyledLgAcceptButton = styled(LgAcceptButton)({
  "span.cost": {
    borderRadius: 16,
    color: "#B54708",
    padding: "2px 8px",
    backgroundColor: "#FFFAEB",
    marginLeft: 8,
    fontSize: 12,
  },
});

const StyledContactForm = styled(Stack)({
  ".header": {
    ".tmp": {
      width: 32,
    },
    img: {
      height: 88,
    },
    button: {
      height: "fit-content",
      padding: 0,
    },
  },
  ".reference-text": {
    color: "#014361",
    backgroundColor: "#E5F6FD",
    padding: 12,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    gap: 4,
    svg: {
      fontSize: 22,
      color: "#03A9F4",
      marginRight: 4,
    },
    span: {
      cursor: "pointer",
      color: "var(--sub-color)",
    },
  },
  ".choose-country": {
    button: {
      minWidth: "fit-content",
    },
  },
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  ".MuiPaper-root": {
    maxWidth: 960,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      height: "100%",
      maxHeight: "100%",
    },
  },
}));

export default memo(GetBusinessCard);
