const initialState = {
  selectedSettingOption: "profile",
  profileData: {},
  profilePress: {},
  profileLatLong: {
    lat: 0.0,
    long: 0.0,
  },
  profileIsEditting: false,
  refreshProfile: false,
};

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PROFILE_DATA": {
      return {
        ...state,
        profileData: action.payload,
      };
    }

    case "SET_PROFILE_IS_EDITTING": {
      return {
        ...state,
        profileIsEditting: action.payload,
      };
    }

    case "SET_PROFILE_PRESS": {
      return {
        ...state,
        profilePress: action.payload,
      };
    }

    case "REFRESH_PROFILE": {
      return {
        ...state,
        refreshProfile: !state?.refreshProfile,
      };
    }

    case "SET_SELECTED_SETTING_OPTION": {
      return {
        ...state,
        selectedSettingOption: action.payload,
      };
    }

    case "SET_PROFILE_LAT_LONG": {
      return {
        ...state,
        profileLatLong: action.payload,
      };
    }

    default:
      return state;
  }
};

export default settingReducer;
