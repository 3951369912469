import { Stack, Typography } from "@mui/material";

import {
  getOrderColorWithStatus,
} from "services/utils/orderHelpers";

const OrderStatusBox = ({ status = 0 }) => {
  const configs = getOrderColorWithStatus(status);

  const { statusBackground, statusColor } = configs;
  return (
    <Stack
      justifyContent="center"
      sx={{ color: statusColor, bgcolor: statusBackground, px: "4px" }}
    >
      <Typography variant="body2" align="center">
        {status}
      </Typography>
    </Stack>
  );
};

export default OrderStatusBox;
