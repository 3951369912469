import React from 'react';
import { Grid, Box, Typography, TextField } from "@mui/material";

import CustomButton from "../CustomButton";


const UpdateAreaDialog = ({
  acceptEvent,
  cancelEvent,
  setContactFreshdiNote,
}) => {
  return (
    <Box textAlign="center">
      <Typography pt={2} mb={4} fontSize="24px" fontWeight="bold">
        Liên hệ với bộ phận CSKH Freshdi
      </Typography>
      <Typography mt={4} my={4} color="#828282">
        Quý khách vui lòng chờ ít phút để nhân viên hỗ trợ hoặc liên hệ qua
        hotline: <strong>0354536389</strong>
      </Typography>
      <Box mx={6}>
        <TextField
          multiline
          rows={4}
          label="Ghi chú (Nếu có yêu cầu cụ thể)."
          onChange={(note) => {
            setContactFreshdiNote(note.target.value);
          }}
          variant="outlined"
          fullWidth={true}
        />
      </Box>
      <Box p={3}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <CustomButton text="Đã xong" fullWidth={true} event={acceptEvent} />
          </Grid>
          <Grid item xs={6}>
            <CustomButton
              text="Huỷ"
              fullWidth={true}
              att={{ color: "black" }}
              event={cancelEvent}
              background="#E0E0E0"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UpdateAreaDialog