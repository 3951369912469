import { apiGetTwlkAccessToken } from "services/api/chat";
import { LOCALSTORAGE_KEYS, chatStorage } from "helpers/localStorage";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addChatConverstation,
  setChatClient,
  setChatConverstations,
  setTwlIdentify,
  setTwlToken,
} from "services/redux/actions/chat";
import { Client } from "@twilio/conversations";
import { getTwlToken } from "services/redux/selectors/chat";
import useChat from "./useChat";
import useUser from "./useUser";

const useTwilio = () => {
  const dispatch = useDispatch();
  const twlToken = useSelector(getTwlToken);
  const { onClearChatKey } = useChat();
  const { isLoggedIn } = useUser();

  const setStoreTwl = (twlk: string, twlid: string) => {
    dispatch(setTwlToken(twlk));
    dispatch(setTwlIdentify(twlid));
  };

  const setLocalStorerageTwl = (twlk: string, twlid: string) => {
    chatStorage.set(LOCALSTORAGE_KEYS.twlk, twlk);
    chatStorage.set(LOCALSTORAGE_KEYS.twlid, twlid);
  };

  const getTwlk = async () => {
    const twlk = chatStorage.get(LOCALSTORAGE_KEYS.twlk);
    const twlid = chatStorage.get(LOCALSTORAGE_KEYS.twlid);
    if (!twlk || !twlid) {
      const data = await apiGetTwlkAccessToken();
      const newTwlk = data.data?.message?.data?.token;
      const newTwlid = data.data?.message?.data?.identity;
      if (newTwlk) {
        setLocalStorerageTwl(newTwlk, newTwlid);
        setStoreTwl(newTwlk, newTwlid);
      }
    } else {
      setStoreTwl(twlk, twlid);
    }
  };

  const onResetTwl = async () => {
    setLocalStorerageTwl("", "");
    await getTwlk();
  };

  useEffect(() => {
    if (isLoggedIn) {
      getTwlk();
    } else {
      onClearChatKey();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (twlToken) {
      try {
        const client = new Client(twlToken);
        client.on("stateChanged", async (state) => {
          if (state === "initialized") {
            dispatch(setChatClient(client));
            let paginator = await client.getSubscribedConversations();
            dispatch(setChatConverstations(paginator.items));
          }
          if (state === "failed") {
            onResetTwl();
          }
        });

        client.on("conversationJoined", async (conversation) => {
          dispatch(addChatConverstation([conversation]));
        });

        client.on("tokenAboutToExpire", async () => {
          onResetTwl();
        });

        client.on("tokenExpired", async () => {
          onResetTwl();
        });

        return () => {
          client?.removeAllListeners();
        };
      } catch (error) {
        console.log(error);
      }
    }
  }, [twlToken]);

  return {};
};

export default useTwilio;
