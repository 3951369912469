import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  aboutText: {
    // textAlign: "justify",
    fontFamily: "'Roboto', sans-serif",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 6,
    letterSpacing: '0.00938em',
    lineHeight: '1.5',
    overflow: "hidden",
    maxWidth: '100%',
    overflowWrap: 'anywhere',
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("sm")]: {
    },
    "& img": {
      maxWidth: "100%",
    },
  },
  customBox: {},
  customShowMoreText: {
    textAlign: "center",
    maxWidth: 1440
  },
  buttonShowMore: {
    display: "inline-flex",
    width: "fit-content",
    color: "#4F4F4F",
    alignItems: "center",
    cursor: "pointer",
    marginTop: 16,
    fontSize: "14px !important",
    [theme.breakpoints.down("md")]: {
      marginTop: 8
    },
  },
  main: {
    '-ms-overflow-style': 'none',
    'scrollbarWidth': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  }
}));
