export const setShowDrawer = (data) => {
  return {
    type: "SET_SHOW_DRAWER",
    payload: data,
  };
};

export const setSelectedOptionIdx = (data) => {
  return {
    type: "SET_SELECTED_OPTION_IDX",
    payload: data,
  };
};

export const changeSplitRight = (data) => {
  return {
    type: "CHANGE_SPLIT_RIGHT",
    payload: data,
  };
};

export const changeSplitLeft = (data) => {
  return {
    type: "CHANGE_SPLIT_LEFT",
    payload: data,
  };
};

export const changeErrorPopup = (data) => {
  return {
    type: "CHANGE_ERROR_POPUP",
    payload: data,
  };
};

export const changeSplitRightPopup = (
  data,
  att = { background: "rgba(0, 0, 0, 0.5)" }
) => {
  return {
    type: "CHANGE_SPLIT_RIGHT_POPUP",
    payload: { ...{ data, att } },
  };
};

export const toggerSplitRightPopup = (data) => {
  return {
    type: "TOGGER_SPLIT_RIGHT_POPUP",
    payload: data,
  };
};

export const changeSplitLeftPopup = (
  data,
  att = { background: "rgba(0, 0, 0, 0.5)" }
) => {
  return {
    type: "CHANGE_SPLIT_LEFT_POPUP",
    payload: { ...{ data, att } },
  };
};

export const toggerSplitLeftPopup = (data) => {
  return {
    type: "TOGGER_SPLIT_LEFT_POPUP",
    payload: data,
  };
};

export const changeCornerIcon = (data) => {
  return {
    type: "CHANGE_CORNER_ICON",
    payload: data,
  };
};

export const toggerCornerIcon = (data) => {
  return {
    type: "TOGGER_CORNER_ICON",
    payload: data,
  };
};

export const changeSideCornerIcon = (data) => {
  return {
    type: "CHANGE_SIDE_CORNER_ICON",
    payload: data,
  };
};

export const changePrintComponent = (data) => {
  return {
    type: "CHANGE_PRINT_COMPONENT",
    payload: data,
  };
};

export const changeWaiting = (data) => {
  return {
    type: "CHANGE_WAITING",
    payload: data,
  };
};
