import axios from "axios";
import { DEFAULT_TOKEN, API_DOMAIN } from "constants/schemas";

export const APITemplate = async (
  end_point,
  data,
  callBack,
  errorCallBack,
  onUploadProgress = null
) => {
  const query_data = {
    server: `${API_DOMAIN}/api/method`,
    end_point: end_point,
  };
  return await axios
    .post(`${query_data.server}/${query_data.end_point}`, data, {
      ...(DEFAULT_TOKEN ? { headers: { Authorization: DEFAULT_TOKEN } } : {}),
      withCredentials: true,
      onUploadProgress: onUploadProgress,
    })
    .then((response) => {
      if (callBack) {
        return callBack(response);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

export const getItemDetail = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.data.trace.farm_item.get_detail",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const getRelateItem = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.data.web.order.menu.get_related_items",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const getOrderDetail = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.data.app.order.get_order_details",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const getAllOrderStatus = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.data.web.order.order.get_all_order_status",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const login = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.data.farm_company.login",
    data,
    (response) => {
      callBack(response);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const getProfile = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.data.farm_company.get_profile",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const getTableDetail = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.data.subfarm_table.get_table",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const getOrdersOfTable = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.data.app.table.get_orders",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const getOrders = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.data.app.homescreen.get_orders_v2",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const updateCustomer = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.data.web.order.order.update_customer",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const getCustomerInfo = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.data.web.order.user.check_exist_customer",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const cancelOrder = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.data.app.order.cancel_order",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const getOrderConfig = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.data.app.payment.get_payment_config",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const getAllTableQRs = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.data.subfarm_table.get_all_table_qrs",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const contactFreshdiCustomerService = (
  data,
  callBack,
  errorCallBack
) => {
  APITemplate(
    "freshdi.data.farm_company.contact_freshdi_customer_service",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const getCustomerService = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.api.web.v2.customer.get_customers",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const getCustomerServiceTags = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.api.web.v2.voucher.get_farm_customer_tags",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const getExportBill = (data, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.data.app.payment.export_bill",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const getVouchers = (data = null, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.api.web.v2.voucher.get_farm_vouchers",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};

export const updateVouchers = (data = null, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.api.web.v2.voucher.update_farm_voucher",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};
export const addVouchers = (data = null, callBack, errorCallBack) => {
  APITemplate(
    "freshdi.api.web.v2.voucher.add_farm_voucher",
    data,
    (response) => {
      callBack(response.data);
    },
    (error) => {
      errorCallBack(error);
    }
  );
};
