import {
  Avatar,
  Box,
  Button,
  Dialog,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useSelector } from "react-redux";
import { FiUser } from "react-icons/fi";

import {
  BaseButton,
  ConfirmBtns,
  Img,
  IntlMsg,
  SmTextSemibold,
  XsTextMd,
  XsTextSemiBold,
} from "components";
// import { getUser } from "redux/selectors/user";
import useAuth from "hooks/useAuth";
import { userSelector } from "services/redux/selectors/userSelector";
import { useIntl } from "react-intl";
import { useMemo, useState } from "react";
import { BIZ_DOMAIN } from "constants/schemas";
import { DIRECT_PATHS, PATHS } from "constants/routes";
import useNavigate from "hooks/useNavigate";
import { CrownTooltip, LevelTooltip, VerifyTooltip } from "./Tooltip";
import { formatImg } from "helpers/format";
import { CIRCLE_VERIFIED_ICON } from "assets/icons";
import useUser from "hooks/useUser";
import { menuCountDataSelector } from "services/redux/selectors/userCommonData";
import { MAX_NOTI_CHAR_QLT } from "constants/layout";
import { useLanguageContext } from "providers/mul-language";
import { addUtmParam } from "helpers/search";

const AuthBar = ({ hideUserName = false }) => {
  const { user, currentPlan, userPoint } = useUser();
  const { verification_level: userLevel } = user.plan || {};
  const { language } = useLanguageContext();

  const intl = useIntl();
  const { navigate, onShowSwitchAccount } = useNavigate();
  const { isLoggedIn, onLogout, onDirectLogin } = useAuth();
  const menuCountStoreData = useSelector(menuCountDataSelector);

  const [openDialog, setOpenDialog] = useState(false);
  const [menuConfig, setMenuConfig] = useState({
    open: false,
    anchorEl: undefined,
  });

  const handleClickLogin = (e) => {
    if (isLoggedIn) {
      setMenuConfig({ open: true, anchorEl: e.currentTarget });
    } else {
      onDirectLogin();
    }
  };

  const directToBiz = (path) => {
    return BIZ_DOMAIN + path;
  };

  const menuItems = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "myQuotations" }),
        href: directToBiz(PATHS.myQuotations),
      },
      {
        label: <IntlMsg id="inquiries" />,
        href: directToBiz(PATHS.inquiries),
        count: menuCountStoreData?.count_inquiries,
      },
      {
        label: <IntlMsg id="products" />,
        href: directToBiz(PATHS.products),
      },
      {
        label: intl.formatMessage({ id: "my_account_settings" }),
        href: directToBiz(PATHS.setting),
      },
      {
        label: <IntlMsg id="go_to_buyer" />,
        href: addUtmParam(PATHS.buyerWorkspace),
      },
      {
        label: intl.formatMessage({ id: "Signout" }),
        event: () => setOpenDialog(true),
      },
    ],
    [user, menuCountStoreData, language]
  );

  const onCloseMenu = () => setMenuConfig({ open: false });

  const onClickMenuItem = (menuItem) => {
    const { path, href } = menuItem;
    if (path || href) {
      if (href) {
        onShowSwitchAccount(href);
      } else {
        navigate(path, { replace: true });
      }
      onCloseMenu();
    } else {
      menuItem?.event && menuItem?.event();
    }
  };

  const onCloseDialog = () => setOpenDialog(false);

  const onConfirmLogout = () => {
    onLogout();
    onCloseDialog();
    setMenuConfig({ open: false });
  };

  return (
    <>
      <StyledAuthButton
        variant="text"
        startIcon={isLoggedIn ? <UserAvatar /> : <FiUser />}
        onClick={handleClickLogin}
        hideUserName={hideUserName}
      >
        {!hideUserName && (
          <Stack>
            <SmTextSemibold className="username" align="left">
              {isLoggedIn
                ? user?.full_name
                : intl.formatMessage({ id: "login" })}
            </SmTextSemibold>
            {isLoggedIn && (
              <Typography fontSize={11} className="quote-text" fontWeight={500}>
                {intl.formatMessage(
                  {
                    id:
                      userPoint > 1 ? "value_quotes_left" : "value_quote_left",
                  },
                  { value: userPoint }
                )}
              </Typography>
            )}
          </Stack>
        )}
      </StyledAuthButton>

      {/* Dropdown content */}
      <StyledMenu
        open={menuConfig.open}
        anchorEl={menuConfig.anchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          horizontal: "right",
        }}
      >
        <Box className="user-info">
          <Stack direction="row" spacing={1} alignItems="center">
            {userLevel && (
              <Link href={PATHS.membershipFull}>
                <VerifyTooltip title={<LevelTooltip level={userLevel} />}>
                  <XsTextMd className="status-label green-label plan">
                    <Img src={CIRCLE_VERIFIED_ICON} alt="icon" />
                    {intl.formatMessage({ id: "verified" })}
                  </XsTextMd>
                </VerifyTooltip>
              </Link>
            )}
            <Link underline="none" href={PATHS.supplierPricing}>
              <CrownTooltip
                title={currentPlan.icon ? <PremiumTooltip /> : null}
              >
                <XsTextMd
                  className="status-label"
                  bgcolor="#fef0c7"
                  color={currentPlan.planTextColor}
                >
                  {currentPlan.icon && (
                    <Img src={currentPlan.icon} alt="icon" />
                  )}
                  {currentPlan.label}
                </XsTextMd>
              </CrownTooltip>
            </Link>
          </Stack>

          <Link href={DIRECT_PATHS.supplierOverview()}>
            <Stack direction="row" spacing="12px" my={1.5}>
              <Img src={formatImg(user.company_logo, 3)} className="avatar" />
              <div>
                <SmTextSemibold
                  sx={{ fontWeight: "600 !important" }}
                  mb={1}
                  className="cutoff-text oneline"
                >
                  {user.farm_name || user.full_name}
                </SmTextSemibold>
                <XsTextMd className="status-label green-label">
                  {intl.formatMessage({ id: "supplier_account" })}
                </XsTextMd>
              </div>
            </Stack>
          </Link>

          <Link href={BIZ_DOMAIN}>
            <BaseButton className="go-work-btn" fullWidth>
              {intl.formatMessage({ id: "go_to_company_workspace" })}
            </BaseButton>
          </Link>

          {(user.full_name || user.email) && (
            <Box mt={2}>
              <XsTextSemiBold
                sx={{ fontWeight: "600 !important" }}
                className="cutoff-text oneline"
              >
                {user.full_name}
              </XsTextSemiBold>
              <XsTextMd mt={0.5} color="var(--gray)">
                {user.email}
              </XsTextMd>
            </Box>
          )}
        </Box>

        {menuItems?.map((menuItem, index) => {
          let dividerItem = index === menuItems.length - 2;
          const count = menuItem.count;
          return (
            <MenuItem
              key={index}
              onClick={() => onClickMenuItem(menuItem)}
              sx={menuItem?.sx}
              style={{
                borderTop: dividerItem ? "1px solid #D0D5DD" : "none",
                marginTop: dividerItem ? 10 : 0,
                marginBottom: index === menuItems.length - 1 ? 10 : 0,
              }}
            >
              <XsTextMd>{menuItem?.label}</XsTextMd>
              {Number(count) > 0 && (
                <p className={"badge-number"}>
                  {Math.min(count, MAX_NOTI_CHAR_QLT)}
                  {count > MAX_NOTI_CHAR_QLT ? "+" : ""}
                </p>
              )}
            </MenuItem>
          );
        })}
      </StyledMenu>

      <StyledDialog open={openDialog} onClose={onCloseDialog}>
        <ConfirmLogoutPopup
          onClickCancel={onCloseDialog}
          onClickConfirm={onConfirmLogout}
        />
      </StyledDialog>
    </>
  );
};

const PremiumTooltip = () => {
  const intl = useIntl();
  return (
    <Typography fontSize={10}>
      {intl.formatMessage({ id: "this_is_a_premium_supplier" })}
    </Typography>
  );
};

const UserAvatar = () => {
  const user = useSelector(userSelector);

  const formatUserName = (name = "") => {
    return name && name?.length > 0 && name[0];
  };

  return (
    <StyledAvatar alt="icon" src={formatImg(user?.user_image, 1)}>
      {user?.full_name && formatUserName(user?.full_name)}
    </StyledAvatar>
  );
};

export const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": { width: "100%", maxWidth: 450 },
});

const ConfirmLogoutPopup = (props) => {
  const { onClickCancel, onClickConfirm } = props;
  return (
    <Box p={2}>
      <Typography>
        <IntlMsg id="confirm_logout" />
      </Typography>
      <ConfirmBtns
        onCancle={onClickCancel}
        onConfirm={onClickConfirm}
        att={confirmBtnStyles}
      />
    </Box>
  );
};

const StyledAvatar = styled(Avatar)({
  width: 32,
  height: 32,
});

const confirmBtnStyles = {
  mt: 2,
  "& .accept": {
    color: "#EB5757",
  },
};

const StyledAuthButton = styled(Button)(({ hideUserName, theme }) => ({
  textTransform: "initial",
  padding: 0,
  "p, svg": {
    color: "var(--gray-500)",
  },
  ".username": {
    maxWidth: 164,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  ".status-label": {
    padding: "2px 8px",
  },
  ".MuiButton-startIcon": {
    marginRight: hideUserName ? 0 : 12,
  },
  ".quote-text": {
    color: "#EB8000",
    fontWeight: 500,
  },
  minWidth: hideUserName ? "fit-content" : 64,
  [theme.breakpoints.down("md")]: {
    paddingLeft: 4,
  },
}));

export const LogoutButton = styled(Button)({
  textTransform: "initial",
  display: "block",
  marginTop: 16,
  fontWeight: 500,
  color: "#344054",
});

export const StyledMenu = styled(Menu)({
  ".MuiPaper-root": {
    width: "100%",
    maxWidth: 284,
    borderRadius: 8,
  },
  p: {
    fontWeight: 500,
  },
  ".MuiList-root": {
    padding: 0,
  },
  ".MuiButtonBase-root": {
    borderRadius: 0,
    padding: "5px 16px",
  },
  ".badge-number": {
    marginLeft: 8,
  },
  ".user-info": {
    padding: "12px 15px",
    ".plan": {},
    ".avatar": {
      minWidth: 60,
      width: 60,
      height: 60,
      objectFit: "cover",
    },
    ".go-work-btn": {
      fontSize: 11,
      padding: 5,
      borderRadius: 8,
    },
  },
});

export default AuthBar;
