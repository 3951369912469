import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  pageBox: {
    display: 'flex',
    alignItems: 'center',
    float: 'right',
    '& svg': {
      fontSize: 16
    }
  }
}));
