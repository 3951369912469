import { Autocomplete, Chip } from "@mui/material";
import React from "react";
import RoundInputWithLabel from "../RoundInputWithLabel";

import useStyles from "./styles";

const MultipleSelect = (props) => {
  const {
    options,
    value,
    handleChange,
    label,
    handleTmpChange,
    tmpChange,
    att,
    textFieldProps,
    chipAtt = {},
    multiple = true,
    otherAtt,
  } = props;
  const classes = useStyles();

  const checkOptionIsString = (option) => {
    return typeof option === "string" || option instanceof String;
  };

  return (
    <Autocomplete
      size="small"
      multiple={multiple}
      sx={{ ...att }}
      fullWidth
      options={options || []}
      className={classes.autoComplete}
      getOptionLabel={(option) => {
        return checkOptionIsString(option) ? option : option?.label;
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={checkOptionIsString(option) ? option : option?.label}
            size="small"
            {...getTagProps({ index })}
            sx={{ ...chipAtt }}
          />
        ))
      }
      value={value}
      onChange={(e, value) => {
        if (handleChange) {
          handleChange(value);
        }
      }}
      renderInput={(params) => {
        return (
          <RoundInputWithLabel
            {...{ ...params, shrink: true }}
            shrink={true}
            label={label}
            onChange={handleTmpChange}
            value={tmpChange}
            {...textFieldProps}
          />
        );
      }}
      freeSolo
      {...otherAtt}
    />
  );
};

export default MultipleSelect;
