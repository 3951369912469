import { Autocomplete, Typography } from "@mui/material";
import React from "react";
import RoundInputWithLabel from "../RoundInputWithLabel";

import useStyles from "./styles";

const SingleSelect = (props) => {
  const {
    options,
    freeSolo = true,
    value,
    onChange,
    label,
    att,
    textFieldProps,
    disabled,
    noOption = <Typography>Không có kết quả</Typography>,
  } = props;
  const classes = useStyles();

  return (
    <Autocomplete
      size="small"
      sx={{ ...att }}
      fullWidth
      disabled={disabled}
      options={options || []}
      className={classes.autoComplete}
      getOptionLabel={(option) => option?.label || ""}
      value={value}
      onChange={(e, value) => onChange(value)}
      noOptionsText={noOption}
      renderInput={(params) => {
        return (
          <RoundInputWithLabel
            {...{ ...params, ...textFieldProps, shrink: true }}
            shrink={true}
            label={label}
          />
        );
      }}
      freeSolo={freeSolo}
    />
  );
};

export default SingleSelect;
