import { Box, Typography, styled } from "@mui/material";
import { useMemo } from "react";
import { GoDotFill } from "react-icons/go";
import { QuotaionStatusType } from "types/quotaion";
type Props = {
  status?: QuotaionStatusType;
};

const QuotationStatus = (props: Props) => {
  const { status } = props;
  const statusConfigs = useMemo(() => {
    switch (status?.value) {
      case "1":
        return { label: status.label, color: "#027B30", bg: "#E3FFEE" };
      case "2":
        return { label: status.label, color: "#344054", bg: "#F2F4F7" };
      case "3":
        return { label: status.label, color: "#B42318", bg: "#FEF3F2" };
      case " 4":
        return { label: status.label, color: "#C4320A", bg: "#FFF6ED" };
      default:
        break;
    }
  }, [status]);

  return (
    <StyledRFQStatus bgsolor={statusConfigs?.bg} scolor={statusConfigs?.color}>
      <GoDotFill />
      <Typography>{statusConfigs?.label}</Typography>
    </StyledRFQStatus>
  );
};

const StyledRFQStatus = styled(Box)<{ bgsolor?: string; scolor?: string }>(
  ({ bgsolor, scolor }) => ({
    padding: "4px 10px 4px 6px",
    p: {
      fontSize: 12,
      fontWeight: 500,
    },
    borderRadius: 16,
    backgroundColor: bgsolor,
    color: scolor,
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    gap: 6,
  })
);

export default QuotationStatus;
