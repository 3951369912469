import dayjs from "dayjs";
import { getConfigs } from "../../configs";
import { DEFAULT_ERROR_MSG } from "constants";
import { DEFAULT_IMG } from "constants/img";

const configs = getConfigs();

export const checkListProtocols = (link = "") => {
  let protocols = ["http", "https"],
    checkListProtocols = false;
  protocols?.map((prototal) => {
    if (link?.indexOf(prototal) > -1) {
      checkListProtocols = true;
    }
    return prototal;
  });
  return checkListProtocols;
};

export const getMatchImageSize = (type = 0) => {
  switch (type) {
    case 1:
      return "128x128";
    case 2:
      return "256x256";
    case 3:
      return "480x480";
    case 4:
      return "640x640";
    case 5:
      return "720x720";
    case 6:
      return "1024x1024";
    default:
      return "256x256";
  }
};

export const getThumbnail = (url, type) => {
  let thumbnailImg = null;
  if (url) {
    thumbnailImg = url.replace("m3u8", "jpeg");
  }
  return `${configs.IMG_SERVER_URL}/${getMatchImageSize(type)}${thumbnailImg}`;
};

const checkIsVideo = (suffix) => {
  let isVideo = false;
  if (suffix) {
    isVideo = suffix?.indexOf(".m3u8") > -1;
  }
  return isVideo;
};

const formatImageUrl = (url, type, isThumbnail = false) => {
  return url
    ? checkListProtocols(url)
      ? url
      : checkIsVideo(url)
      ? isThumbnail
        ? getThumbnail(url, type)
        : `${configs.IMG_SERVER_URL}${url}`
      : type
      ? `${configs.IMG_SERVER_URL}/${getMatchImageSize(type)}${url}`
      : `${configs.SERVER_IMAGE_URL}${url}`
    : DEFAULT_IMG;
};

const validateCommon = (type, value) => {
  if (!value) return type === "money" ? 0 : "";

  if (type === "phone") {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return value.match(phoneno) ? true : false;
  }

  if (type === "email") {
    var emailno =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailno.test(String(value).toLowerCase()) ? true : false;
  }

  if (type === "onlyText") {
    return /^[A-Za-z ]+$/.test(value) ? true : false;
  }

  if (type === "onlyNumber") {
    return value.match(/^-?\d*(\.\d+)?$/) ? true : false;
  }

  if (type === "money") {
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  if (type === "date") {
    return value.split("-").reverse().join("/");
  }

  if (type === "getTimeFromCreation") {
    return value.split(" ")[1]?.split(":").slice(0, 2)?.join(":");
  }

  if (type === "getDateFromCreation") {
    return value.split(" ")[0]?.split("-")?.reverse()?.join("/");
  }

  if (type === "formatCreation") {
    let date = value.split(" ")[0]?.split("-")?.reverse()?.join("/");
    let time = value.split(" ")[1]?.split(":").slice(0, 2)?.join(":");
    return `${time} ${date}`;
  }

  if (type === "timestamp") {
    if (value > 0) {
      var datetime = new Date((value || 0) * 1000);
      var ts = datetime?.toLocaleDateString()?.split("/");
      return `${ts[1]}/${ts[0]}/${
        ts[2]
      } ${datetime?.getHours()}:${datetime?.getMinutes()}`;
    }
    return null;
  }
};

const checkValidPhone = (phone) => {
  var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (phone && phone.match(phoneno)) {
    return true;
  } else {
    return false;
  }
};

const checkValidString = (string) => {
  return string && string?.toString()?.trim() !== "" ? true : false;
};

const formatDate = (date, format = "DD/MM/YYYY") => {
  return date && dayjs(date).format(format);
};

const DMS2DD = (degrees, minutes, seconds, direction) => {
  var dd = degrees + minutes / 60 + seconds / 3600;
  if (direction === "S" || direction === "W") {
    dd = dd * -1;
  }
  return dd;
};

const genFileName = (type = "png") => {
  let date = new Date();
  let fileName = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}-${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}.${type}`;
  return fileName;
};

const camalize = function camalize(str) {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

const formatError = (err) => {
  try {
    const errorRes = (JSON.parse(err?.response?.data?._server_messages) ||
      "[{}]")[0];
    return JSON.parse(errorRes)?.message || DEFAULT_ERROR_MSG;
  } catch (error) {
    return DEFAULT_ERROR_MSG;
  }
};

const formatAddressOptions = (options) => {
  return options?.map((option) => ({
    label: option?.name,
    value: option?.codename,
  }));
};

export {
  formatImageUrl,
  validateCommon,
  checkValidPhone,
  checkValidString,
  formatDate,
  DMS2DD,
  genFileName,
  checkIsVideo,
  camalize,
  formatError,
  formatAddressOptions,
};
