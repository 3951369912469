import { Box, BoxProps, Divider, Typography, styled } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  text?: ReactNode;
};

const TextOnLine = ({ text, ...props }: Props & BoxProps) => {
  return (
    <StyledBox {...props}>
      <Divider />

      <Typography variant="body2" className="text">
        {text}
      </Typography>
    </StyledBox>
  );
};

const StyledBox = styled(Box)({
  position: "relative",
  "& .text": {
    position: "absolute",
    right: "50%",
    top: "-50%",
    padding: "0 24px",
    backgroundColor: "#fff",
    transform: "translate(50%, -50%)",
    color: "#ccc",
  },
});

export default TextOnLine;
