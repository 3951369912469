import React from "react";
import { Box, Typography, styled } from "@mui/material";

import noteIcon from "assets/icons/note.png";

const NullBox = (props) => {
  const { text, bonusHeight = 0 } = props;

  return (
    <CustomBox {...{ bonusHeight }}>
      <Box>
        <img src={noteIcon} alt="logo" />
        <Typography variant="body2">{text}</Typography>
      </Box>
    </CustomBox>
  );
};

const CustomBox = styled(Box)(({ theme, bonusHeight=0 }) => ({
  width: "100%",
  minHeight: `calc(100vh - ${64 + bonusHeight}px)`,
  [theme.breakpoints.down("md")]: {
    minHeight: `calc(100vh - ${56 + bonusHeight}px)`,
  },
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#828282",
  "& img": {
    marginBottom: 16,
    height: 24,
  },
}));

export default NullBox;
