import { Box, styled } from "@mui/material";
import { IMG_DOMAIN } from "constants/schemas";
import { useEffect, useState } from "react";

const HtmlViewer = ({ content = "", ...otherProps }) => {
  const [updatedHtml, setUpdateHtml] = useState();

  useEffect(() => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

    const imgElements = tempDiv.querySelectorAll("img");

    imgElements.forEach((img) => {
      const currentSrc = img.getAttribute("src");
      if (
        currentSrc &&
        !currentSrc.startsWith("http") &&
        !currentSrc.startsWith("data:image/")
      ) {
        img.setAttribute("src", `${IMG_DOMAIN}${currentSrc}`);
      }
    });

    setUpdateHtml(tempDiv.innerHTML);
  }, [content, IMG_DOMAIN]);

  return (
    <StyledBox
      dangerouslySetInnerHTML={{ __html: updatedHtml }}
      {...otherProps}
    />
  );
};

const StyledBox = styled(Box)({
  "& p": {
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  img: {
    maxWidth: "100%",
    maxHeight: 244,
  },
});

export default HtmlViewer;
