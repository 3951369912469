const initialState = {
  tables: [],
  refreshTable: false,
  selectedTable: null
};

const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_TABLE": {
      return {
        ...state,
        selectedTable: action.payload,
      };
    }
    
    case "CHANGE_TABLES": {
      return {
        ...state,
        tables: action.payload,
      };
    }

    case "REFRESH_TABLE": {
      return {
        ...state,
        refreshTable: !state?.refreshTable,
      };
    }

    default:
      return state;
  }
};

export default tableReducer;
