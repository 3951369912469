import React, { useMemo, useRef } from "react";
import ChartPie from "react-apexcharts";
import ApexCharts from "apexcharts";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const options = {
  plotOptions: {
    pie: {
      donut: {
        size: "55%",
      },
      dataLabels: {
        offset: 1000,
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  noData: {
    // text: 'Không có dữ liệu',
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: "14px",
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          show: false,
          position: "right",
        },
      },
    },
  ],
};
const ChartDonut = ({
  series = [],
  labels = [],
  colors = [],
  isEmptyData,
  total,
  tableKey,
  tabKey,
  att,
}) => {
  const nav = useNavigate();
  const chartRef = useRef();

  const textConfigs = useMemo(() => {
    switch (tableKey) {
      case "get_revenue_table":
        return { uom: "VND", title: "Tổng số đơn" };
      case "get_visitors_pie_chart":
        return {
          uom: "lượt",
          title: "Lượt quét mã QR",
          canDirect: true,
        };
      case "get_statistic":
        return {
          title: "Thống kê theo tỉnh thành",
        };
      default:
        return { uom: "đơn", title: "Tổng doanh thu" };
    }
  }, [tableKey]);

  const toggle = (label) => {
    if (textConfigs?.canDirect) {
      if (label?.category_id && label?.qr_id) {
        nav(`/manage-qr/${label?.category_id}/${label?.qr_id}`);
      }
    } else {
      ApexCharts.exec(`${tableKey}-${tabKey}`, "hideSeries", [label]);
    }
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      flex={1}
      flexDirection={{ xs: "column", md: "row" }}
      sx={att}
    >
      <Box
        display={"flex"}
        flex={1}
        justifyContent={{ xs: "center", md: "flex-end" }}
        mb={{ xs: 4, md: 0 }}
      >
        <ChartPie
          ref={chartRef}
          options={{
            chart: {
              id: `${tableKey}-${tabKey}`,
              type: "donut",
              events: {
                dataPointSelection: (event, chartContext, config) => {
                  toggle(config.w.config.labels[config.dataPointIndex]);
                },
              },
            },
            colors,
            ...options,
            plotOptions: {
              ...options.plotOptions,
              pie: {
                ...options.plotOptions.pie,
                dataLabels: {
                  offset: isEmptyData ? 1000 : 0,
                },
              },
            },
            labels,
          }}
          series={series}
          type="donut"
          width="228"
          height="228"
        />
      </Box>
      <Box flex={1} ml={4}>
        <Box mb={2}>
          <Typography mb={1} fontWeight={500}>
            {textConfigs?.title}
          </Typography>
          <Typography fontSize={20} fontWeight={500}>
            {total || 0} {textConfigs?.uom}
          </Typography>
        </Box>
        <Box>
          {labels.map((label, index) => (
            <Box
              key={index}
              display={"flex"}
              alignItems={"center"}
              gap={1}
              mb={1}
            >
              <Box
                sx={{
                  width: "9px",
                  height: "9px",
                  borderRadius: "50%",
                  backgroundColor: colors[index],
                }}
              />
              <Typography
                onClick={() => toggle(label)}
                fontSize={"small"}
                sx={{ cursor: "pointer" }}
              >
                {label?.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ChartDonut;
