import React from "react";
import { styled } from "@mui/material/styles";
import { IconButton, Typography } from "@mui/material";
// import useStyles from './styles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";

export default function ExpandMore({
  expanded,
  handleExpandClick,
  disable = false,
  showText = false,
  icon = <ExpandMoreIcon />,
  att,
}) {
  // const classes = useStyles();

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} sx={{ ...att }} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  return (
    <Box display="flex" alignItems="center">
      {showText && (
        <Typography variant="body2">
          {expanded ? "Thu gọn" : "Mở rộng"}
        </Typography>
      )}
      <ExpandMore
        expand={expanded}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
      >
        {icon}
      </ExpandMore>
    </Box>
  );
}
