import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  main: {
    display: "flex",
    backgroundColor: "rgb(0,0,0, 0.3)",
    height: "100%",
    position: "fixed",
    border: "1px solid #E0E0E0",
    zIndex: 2
  },
  content: {
    backgroundColor: "white",
    width: "100%",
    height: "fit-content",
    // position: 'relative',
    maxHeight: "80vh",
    overflow: "auto",
    '-ms-overflow-style': 'none',
    'scrollbarWidth': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
}));
