import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  selectCustomerBos: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 2,
    transform: "translateY(100%)"
  },
  selectCustomerContent: {
    maxHeight: 200,
    overflow: 'hidden',
    overflowY: 'scroll',
    backgroundColor: '#fff',
    borderRadius: '0 0 8px 8px',
    border: '1px solid #ddd',
    '-ms-overflow-style': 'none',
    'scrollbarWidth': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    "& .customerBox": {
      borderRadius: 8,
      "&:hover": {
        backgroundColor: "#ddd"
      }
    }
  }
}))