import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { compose, withProps } from "recompose";

const GM_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API;

const Map = compose(
  withProps((props) => {
    return {
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `400px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
      googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GM_API_KEY}`,
      ...props,
    };
  }),
  withScriptjs,
  withGoogleMap
)(({ latLng = { lat: 0, lng: 0 }, ...props }) => (
  <GoogleMap defaultZoom={11} center={latLng} {...props}>
    <Marker position={latLng}></Marker>
  </GoogleMap>
));

export default Map;
