import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  main: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    backgroundColor: "#fff",
    borderRadius: 16,
    width: "85%",
    maxWidth: 650,
    minWidth: 250,
    textAlign: "center",
  },
}));
