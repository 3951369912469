import { useCallback, useMemo, useState } from "react";

const useMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  const onCloseMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  const onClickMenu = (e: any) => {
    setAnchorEl(e?.currentTarget);
    setShowMenu(true);
  };

  return useMemo(
    () => ({
      showMenu,
      onCloseMenu,
      onClickMenu,
      anchorEl,
    }),
    [showMenu, onCloseMenu, onClickMenu, anchorEl]
  );
};
export default useMenu;
