const initialState = {
  refreshCustomerTag: false
};

const customerTagReducer = (state = initialState, action) => {
  switch (action.type) {

    case "REFRESH_CUSTOMER_TAG": {
      return {
        ...state,
        refreshCustomerTag: !state?.refreshCustomerTag,
      };
    }

    default:
      return state;
  }
};

export default customerTagReducer;
