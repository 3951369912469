import { Box } from "@mui/material";
import React from "react";

import useStyles from "./styles";

const StepList = ({ steps = [], isVer = true, att }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.sampleStepsBox}
      sx={{
        display: isVer ? "block" : "flex",
        overflow: isVer? "initial": "hidden",
        overflowX: isVer? "initial": "scroll",
        ...att,
      }}
    >
      {steps?.map((step, index) => (
        <Box
          className={classes.stepBox}
          key={index}
          sx={{
            width: isVer ? "100%" : 250,
            borderLeft: isVer ? "1px solid #B7DEC1" : "initial",
            borderTop: isVer ? "initial" : "1px solid #B7DEC1",
          }}
        >
          <Box
            className={classes.leafve}
            sx={{
              top: isVer ? "50%" : 0,
              transform: ` 
                translate(${index % 2 !== 0 ? "-100%" : "0"}, -50%) 
                rotate(${
                  isVer
                    ? index % 2 !== 0
                      ? "135deg"
                      : 0
                    : index % 2 !== 0
                    ? "45deg"
                    : "90deg"
                })`,
            }}
          />
          <Box
            className={classes.step}
            sx={{ width: isVer ? "calc(100% - 16px)" : "100%" }}
          >
            {step}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default StepList;
