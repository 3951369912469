import { Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

import useStyles from "./styles";

const TagBox = (props) => {
  const classes = useStyles();
  const { text, fontSize = 12, handleRemove, att } = props;
  return (
    <Box
      className={classes.main}
      sx={{ "& p, svg": { fontSize: fontSize }, ...att }}
    >
      <Typography>{text}</Typography>
      <Close onClick={(e) => handleRemove(e)} />
    </Box>
  );
};

export default TagBox;
