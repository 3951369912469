import { SVGProps } from "react";

export function OpenEyeIcon({
  width = 16,
  height = 16,
  viewBox = "0 0 16 16",
  stroke = "#344054",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <g clip-path="url(#clip0_9901_82285)">
        <path
          d="M0.666687 7.99935C0.666687 7.99935 3.33335 2.66602 8.00002 2.66602C12.6667 2.66602 15.3334 7.99935 15.3334 7.99935C15.3334 7.99935 12.6667 13.3327 8.00002 13.3327C3.33335 13.3327 0.666687 7.99935 0.666687 7.99935Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.00002 9.99935C9.10459 9.99935 10 9.10392 10 7.99935C10 6.89478 9.10459 5.99935 8.00002 5.99935C6.89545 5.99935 6.00002 6.89478 6.00002 7.99935C6.00002 9.10392 6.89545 9.99935 8.00002 9.99935Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9901_82285">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CloseEyeIcon({
  width = 16,
  height = 16,
  viewBox = "0 0 16 16",
  stroke = "#344054",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <g clip-path="url(#clip0_9901_82194)">
        <path
          d="M6.60002 2.82602C7.05891 2.7186 7.52873 2.66491 8.00002 2.66602C12.6667 2.66602 15.3334 7.99935 15.3334 7.99935C14.9287 8.75642 14.4461 9.46917 13.8934 10.126M9.41335 9.41268C9.23025 9.60918 9.00945 9.76678 8.76412 9.8761C8.51879 9.98541 8.25395 10.0442 7.98541 10.0489C7.71687 10.0537 7.45013 10.0043 7.20109 9.90368C6.95206 9.80309 6.72583 9.65337 6.53592 9.46345C6.346 9.27353 6.19628 9.04731 6.09569 8.79827C5.9951 8.54924 5.9457 8.2825 5.95044 8.01395C5.95518 7.74541 6.01396 7.48058 6.12327 7.23525C6.23258 6.98992 6.39019 6.76911 6.58669 6.58602M0.666687 0.666016L15.3334 15.3327M11.96 11.9593C10.8204 12.828 9.43276 13.3093 8.00002 13.3327C3.33335 13.3327 0.666687 7.99935 0.666687 7.99935C1.49595 6.45395 2.64611 5.10375 4.04002 4.03935L11.96 11.9593Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9901_82194">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
