import { combineReducers } from "redux";
import cartReducer from "./cart";
import layoutReducer from "./layout";
import userReducer from "../redux/reducers/user";
import orderReducer from "./order";
import notificationReducer from "./notification";
import tableReducer from "./table";
import agentReducer from "./agent";
import customerReducer from "./customer";
import customerTagReducer from "./customerTag";
import voucherReducer from "./voucher";
import voucherCampaignReducer from "./voucherCampaign";
import itemReducer from "./item";
import qrReducer from "./qr";
import settingReducer from "./setting";
import stampReducer from "./stamp";
import shippingReducer from "./shipping";
import iotZoneReducer from "./iotZone";
import commonDataReducer from "services/redux/reducers/common-data";
import chatReducer from "services/redux/reducers/chat";

export default combineReducers({
  cartReducer,
  layoutReducer,
  userReducer,
  orderReducer,
  notificationReducer,
  tableReducer,
  agentReducer,
  customerReducer,
  customerTagReducer,
  voucherReducer,
  voucherCampaignReducer,
  itemReducer,
  qrReducer,
  settingReducer,
  stampReducer,
  shippingReducer,
  iotZoneReducer,
  commonDataReducer,
  chatReducer,
});
