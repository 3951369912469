import { LOGOUT_USER, SET_USER, UPDATE_USER_POINT } from "../actionTypes";
export const USER_ACTIONS = {
  updateUserContactQuote: "UPDATE_USER_CONTACT_QUOTE",
  updateUserCreditChat: "UPDATE_USER_CREDIT_CHAT",
};

export const setUser = (data: any) => {
  return {
    type: SET_USER,
    payload: data,
  };
};

export const updateUserPoint = (point?: string) => {
  return {
    type: UPDATE_USER_POINT,
    payload: point,
  };
};

export const updateUserContactQuote = (point?: string) => {
  return {
    type: USER_ACTIONS.updateUserContactQuote,
    payload: point,
  };
};

export const updateUserCreditChat = (point?: string) => {
  return {
    type: USER_ACTIONS.updateUserCreditChat,
    payload: point,
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};
