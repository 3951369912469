import { SVGProps } from "react";

export function EmailIcon({
  width = 22,
  height = 18,
  viewBox = "0 0 22 18",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M21 3C21 1.9 20.1 1 19 1H3C1.9 1 1 1.9 1 3M21 3V15C21 16.1 20.1 17 19 17H3C1.9 17 1 16.1 1 15V3M21 3L11 10L1 3"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Email2Icon({
  width = 20,
  height = 20,
  viewBox = "0 0 20 20",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M18.3334 5.00016C18.3334 4.0835 17.5834 3.3335 16.6667 3.3335H3.33341C2.41675 3.3335 1.66675 4.0835 1.66675 5.00016M18.3334 5.00016V15.0002C18.3334 15.9168 17.5834 16.6668 16.6667 16.6668H3.33341C2.41675 16.6668 1.66675 15.9168 1.66675 15.0002V5.00016M18.3334 5.00016L10.0001 10.8335L1.66675 5.00016"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
