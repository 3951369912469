const initialState = {
  isCreatingStamp: true,
  selectedStamp: {}
};

const stampReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_IS_CREATING_STAMP": {
      return {
        ...state,
        isCreatingStamp: action.payload,
      };
    }

    case "SET_SELECTED_STAMP": {
      return {
        ...state,
        selectedStamp: action.payload,
      };
    }

    default:
      return state;
  }
};

export default stampReducer;
