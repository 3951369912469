
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  mainImgBox: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
    },
    overflow: 'hidden',
    position: 'relative',
    '& button:hover': {
      background: '#E98100',
      color: '#fff'
    },
    display: 'flex',
    justifyContent: 'center'
  },
  blurImg: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    filter: 'blur(15px)',
    position: 'absolute'
  },
  img: {
    zIndex: 2,
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
  },
}))