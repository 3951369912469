import { SEARCH_PARAMS } from "constants/routes";

export function objectToQueryString(obj: any) {
  const keys = Object.keys(obj);
  const keyValuePairs = keys
    .filter((key) => [undefined, null, ""].indexOf(obj[key]) < 0)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
    });
  return keyValuePairs.join("&");
}

export const addUtmParam = (url = "") => {
  if (!url) return url;

  const parsedUrl = new URL(url);
  const urlParams = new URLSearchParams(parsedUrl.search);
  const localUtmSource = localStorage.getItem(SEARCH_PARAMS.utmSource);

  if (!localUtmSource) return url;

  const delimiter = urlParams.toString() ? "&" : "?";
  return `${url}${delimiter}${SEARCH_PARAMS.utmSource}=${localUtmSource}`;
};
