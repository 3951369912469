import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  notiItem: {
    border:"solid #D3D3D3 1px",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    cursor:"pointer",
  }
}))