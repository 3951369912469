import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { changeErrorPopup } from "services/action/layoutActions";
import ErrorPopup from "./ErrorPopup";

const ToggerError = () => {
  const dispatch = useDispatch();
  const errorPopup = useSelector((state) => state?.layoutReducer?.errorPopup);

  const onCloseWaiting = () => {
    dispatch(changeErrorPopup({ ...errorPopup, open: false }));
  };

  return (
    <Dialog open={errorPopup?.open} onClose={onCloseWaiting}>
      <ErrorPopup onClickOut={onCloseWaiting} text={errorPopup?.text} />
    </Dialog>
  );
};

export default ToggerError;
