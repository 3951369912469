import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  background: {
    display: "flex",
    alignItems: 'flex-end',
    width: "100%"
  },
  main: {
    backgroundColor: "#fff",
    height: "fit-content",
    width: "100%",
    // zIndex: 5
  },
  tab: {
    textTransform: "initial !important",
  },
  dot: {
    top: "0px",
    right: "0px",
    height: "8px",
    width: "8px",
    backgroundColor: "red",
    borderRadius: "50%",
    display: "inline-block",
  },
  filterButton: {
    m: 1,
    border: "1.5px solid black",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  filterTopText: {
    textTransform: "none",
    fontWeight: "600 !important",
    display: "flex",
    alignItems: "center",
    "& p": {
    },
  },
  btn: {
    "& img": { width: "20px" },
  },
  nullBox: {
    width: "100%",
    minHeight: "65vh",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#828282",
    "& img": {
      marginBottom: 16,
      height: 24,
    },
  },
}));
