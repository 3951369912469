import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useSearchParam = () => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  return useMemo(
    () => ({
      searchParams,
    }),
    [searchParams]
  );
};

export default useSearchParam;
