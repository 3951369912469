const initialState = {
  selectedOrder: null,
  refreshOrder: false,
  reloadOrderDetail: false,
  updateOrderParams: {},
  addItemMode: false,
  chooseTableForTable: null,
  menu: [],
  categories: [],
  orderTabsBar: [],
  orderData: {
    data: [],
    count: 0,
    total: 0,
  },
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_ORDER": {
      return {
        ...state,
        selectedOrder: action.payload,
      };
    }

    case "REFRESH_ORDER": {
      return {
        ...state,
        refreshOrder: !state?.refreshOrder,
      };
    }

    case "RELOAD_ORDER_DETAIL": {
      return {
        ...state,
        reloadOrderDetail: !state?.reloadOrderDetail,
      };
    }

    case "UPDATE_ORDER_PARAMS": {
      return {
        ...state,
        updateOrderParams: action.payload,
      };
    }

    case "TOGGER_ADD_ITEM_MODE": {
      return {
        ...state,
        addItemMode: action.payload,
      };
    }

    case "CHOOSE_TABLE_FOR_ORDER": {
      return {
        ...state,
        chooseTableForTable: action.payload,
      };
    }

    case "CHANGE_MENU": {
      return {
        ...state,
        menu: action.payload,
      };
    }

    case "CHANGE_CATEGORIES": {
      return {
        ...state,
        categories: action.payload,
      };
    }

    case "CHANGE_ORDER_TABS_BAR": {
      return {
        ...state,
        orderTabsBar: action.payload,
      };
    }

    case "CHANGE_ORDER_DATA": {
      return {
        ...state,
        orderData: action.payload,
      };
    }

    default:
      return state;
  }
};

export default orderReducer;
