import React from "react";
import { CircularProgress } from "@mui/material";
import useStyles from "./styles";

const Wait = () => {
  const classes = useStyles();
  return (
    <div className={classes.popupBox}>
      <div className={classes.box}>
        <CircularProgress size={30} style={{ color: "#E98100" }} />
      </div>
    </div>
  );
};

export default Wait;
