import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useRef } from "react";

import useStyles from "./styles";

export default function CustomDialog({
  content,
  showDia,
  onClose,
  showClose = true,
  width,
  att,
  contentAtt,
}) {
  const companyNameElement = document.getElementById("gridSplitLeft");
  const classes = useStyles();
  const wrapperRef = useRef(null);

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //       onClose();
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [])
  
  return (
    <>
      {showDia && (
        <Box
          className={classes.main}
          width={width || companyNameElement?.offsetWidth}
          // top={headerElement?.offsetTop + headerElement?.offsetHeight}
          top={0}
          // left={leftBarBox?.clientWidth}
          left={0}
          id="dialogBackground"
          sx={{ ...att }}
        >
          <Box
            ref={wrapperRef}
            className={classes.content}
            sx={{ ...contentAtt }}
          >
            {showClose && (
              <Box position="absolute" left="8px" top="0px" onClick={onClose}>
                <IconButton aria-label="delete">
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
            {content}
          </Box>
        </Box>
      )}
    </>
  );
}
