import { Box, InputBase } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";

import useStyles from "./styles";

const SearchInput = (props) => {
  const {
    placeholder = "Nhập tên sản phẩm",
    handleChangeSearchKey,
    att,
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.searchBox} sx={{ ...att }}>
      <Box className={classes.searchContent}>
        <SearchIcon sx={{ mx: 1 }} />
        <InputBase placeholder={placeholder} onChange={handleChangeSearchKey} />
      </Box>
    </Box>
  );
};

export default SearchInput;
