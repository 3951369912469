import { styled } from "@mui/material";
import { API_DOMAIN } from "constants/schemas";
import { checkFileIsImage } from "helpers/file";
import { TiAttachmentOutline } from "react-icons/ti";
import { formatImg } from "helpers/format";
import { useEffect, useState } from "react";
import { Media } from "@twilio/conversations";
import { AttachFile } from "@mui/icons-material";
import { OpenTabLink } from "./Text";
type Props = {
  attachment: string;
};

const Attachment = ({ attachment }: Props) => {
  return (
    <StyledAttachment
      href={`${API_DOMAIN}${attachment}`}
      target="_blank"
      download
      className="attachment"
    >
      {checkFileIsImage(attachment) ? (
        <img src={formatImg(attachment, 1)} />
      ) : (
        <TiAttachmentOutline />
      )}
    </StyledAttachment>
  );
};

const StyledAttachment = styled("a")({
  width: 60,
  height: 60,
  border: "1px solid #D0D5DD",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 4,
  color: "#000",
  cursor: "pointer",
  img: {
    width: "100%",
    objectFit: "cover",
    height: "100%",
  },
  svg: {
    width: 48,
    height: 48,
  },
});

const ChatAttachment = ({ medias }: { medias: Media[] }) => {
  const [mediaElements, setMediaElements] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const renderMedia = async () => {
      const elements: JSX.Element[] = [];
      for (let i = 0; i < medias.length; i++) {
        const item = medias[i];
        const url = await item.getContentTemporaryUrl();
        if (item.contentType.startsWith("image/")) {
          elements.push(
            <StyledChatAttachment key={i} href={url || "#"}>
              <img src={url || ""} alt="img" className="chat-img" />
            </StyledChatAttachment>
          );
        } else {
          elements.push(
            <StyledAttachment key={i} href={url || "#"}>
              <AttachFile />
            </StyledAttachment>
          );
        }
      }
      setMediaElements(elements);
    };

    renderMedia();
  }, [medias]);

  return <>{mediaElements}</>;
};

const StyledChatAttachment = styled(OpenTabLink)({});

export default Attachment;
export { ChatAttachment };
