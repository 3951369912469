import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  qrImgBox: {
    backgroundColor: "var(--header-green-color)",
    textAlign: "center",
    position: "relative",
    "& .qrImg": {
      maxWidth: "100%",
      borderRadius: 4,
    },
    "& .scan": {
      maxWidth: "100%",
    },
    "& .expBox": {
      position: "absolute",
      width: "100%",
      backgroundColor: "rgba(217, 217, 217, 0.5)",
      height: "100%",
      top: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));
