const initialState = {
  cameras: [],
  edited: false,
  selectedCamera: null,
  cameraParams: {},
  refreshTable: false,
  refreshCamera: false,
  pressingParams: {},
  cameraDetail: {}
};

const iotZoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_CAMERA": {
      return {
        ...state,
        selectedCamera: action.payload,
      };
    }
    
    case "CHANGE_CAMERAS": {
      return {
        ...state,
        cameras: action.payload,
      };
    }

    case "CANCEL_EDIT": {
      return {
        ...state,
        edited: !action.payload,
      };
    }

    case "CHANGE_CAMERA_PARAMS": {
      return {
        ...state,
        cameraParams: action.payload
      };
    }

    case "REFRESH_TABLE": {
      return {
        ...state,
        refreshTable: !state?.refreshTable
      };
    }

    case "REFRESH_CAMERA": {
      return {
        ...state,
        refreshCamera: !state?.refreshCamera
      };
    }

    case "SET_PRESSING_PARAMS": {
      return {
        ...state,
        pressingParams: action.payload
      };
    }

    case "SET_CAMERA_DETAIL": {
      return {
        ...state,
        cameraDetail: action.payload
      };
    }

    default:
      return state;
  }
};

export default iotZoneReducer;
