import React from "react";
import { Box, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";

import useStyles from "./styles";
import { ExpandMore } from "components";

const AddBox = (props) => {
  const classes = useStyles();
  const {
    handleClickAdd,
    showFull,
    setShowFull,
    text,
    hiddenCollapse = false,
    sx,
  } = props;

  return (
    <Box className={classes.addBtnBox} sx={sx}>
      <Box className="addBox" onClick={handleClickAdd}>
        <Typography variant="body2">{text}</Typography>
        <AddCircle sx={{ mx: 1 }} />
      </Box>
      {!hiddenCollapse && (
        <ExpandMore
          att={{ p: 0 }}
          expanded={showFull}
          handleExpandClick={() => setShowFull(!showFull)}
        />
      )}
    </Box>
  );
};

export default AddBox;
