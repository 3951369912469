import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  main: {
    zIndex: "5 !important",
    "& .MuiPaper-root": {
      boxShadow: "initial",
      '-ms-overflow-style': 'none',
      'scrollbarWidth': 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
    }
  },
  content: {
    backgroundColor: "#fff",
    borderRadius: 16
  },
}));
