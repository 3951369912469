const initialState = {
	vouchers: [],
	refreshVoucher: false,
	isLoading: true,
	selectedVoucher: null
};

const voucherReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_VOUCHERS": {
			return {
				...state,
				vouchers: action.payload,
				isLoading: action.isLoading
			};
		}

		case "SET_SELECTED_VOUCHER": {
			return {
				...state,
				selectedVoucher: action.payload,
			};
		}

		case "REFRESH_TABLE": {
			return {
				...state,
				refreshVoucher: !state?.refreshVoucher,
			};
		}

		default:
			return state;
	}
};

export default voucherReducer;
