import { Stack, styled } from "@mui/material";
import { QUOTE_LEFT_CONTACT_BLUR_IMG } from "assets/images";
import {
  CancelButton,
  ConfirmButton,
  Img,
  OpenTabLink,
  Row,
  TextSemibold,
} from "components";
import { PATHS } from "constants/routes";
import { memo } from "react";
import { useIntl } from "react-intl";

type Props = {};

const ContactBuyerBlocker = (props: Props) => {
  const t = useIntl();

  return (
    <StyledContactBuyerBlocker>
      <Img src={QUOTE_LEFT_CONTACT_BLUR_IMG} alt="img" className="blur-img" />

      <CommonBlocker
        title={t.formatMessage({ id: "post_quote_blocker_desc" })}
      />
    </StyledContactBuyerBlocker>
  );
};

export const CommonBlocker = ({ title }: { title: string }) => {
  const t = useIntl();
  return (
    <StyledCommonBlocker spacing={2} textAlign="center" className="content">
      {/*<Img src={RED_WARNING_ICON} alt="icon" className="warning-icon" />*/}
      <TextSemibold className="blur-desc">{title}</TextSemibold>
      <OpenTabLink href={PATHS.supplierPricing}>
        <ConfirmButton>
          {t.formatMessage({ id: "become_premium_membership" })}
        </ConfirmButton>
      </OpenTabLink>

      <Row justifyContent="center" className="btns-box">
        <OpenTabLink href={PATHS.contactUs}>
          <CancelButton>{t.formatMessage({ id: "contact_sale" })}</CancelButton>
        </OpenTabLink>
      </Row>
    </StyledCommonBlocker>
  );
};

const StyledCommonBlocker = styled(Stack)({
  justifyContent: "center",
  alignItems: "center",

  button: {
    lineHeight: "20px",
    fontSize: 14,
    padding: 8,
  },
  ".btns-box": {
    button: {
      width: 110,
    },
  },
});

const StyledContactBuyerBlocker = styled(Stack)({
  position: "relative",
  marginTop: 0,
  ".warning-icon": {
    width: 54,
  },
  ".blur-img": {
    width: "100%",
  },
  ".content": {
    position: "absolute",
    height: "calc(100% - 24px)",
    width: "calc(100% - 16px)",
    padding: 16,
    left: -8,
    ".blur-desc": {
      maxWidth: 196,
    },
  },
});

export default memo(ContactBuyerBlocker);
