import { Checkbox, Radio } from "@mui/material";

export const getOrderColorWithStatus = (status, forOrder = false) => {
  switch (status) {
    case "Đã đặt":
      return {
        statusColor: "#FF0000",
        statusBackground: "rgba(242, 153, 74, 0.1)",
      };
    case "Chờ xác nhận":
      return {
        statusColor: "#FF0000",
        statusBackground: "rgba(242, 153, 74, 0.1)",
      };
    case "Đang thực hiện":
      return {
        statusColor: "#E98100",
        statusBackground: "rgba(242, 153, 74, 0.1)",
      };
    case "Hoàn thành":
      return {
        statusColor: "#008934",
        statusBackground: "#E0FFEC",
      };
    case "Đã hủy":
      return {
        statusColor: "#000",
        statusBackground: "#E1E1E1",
      };
    default:
      return {
        statusColor: "black",
        statusBackground: "#E1E1E1",
      };
  }
};

export const getShippingStatusConfigs = (status = 0) => {
  // dict: key: [text color, background color]
  let configs = {
    0: ["#EE0000", "rgba(238, 0, 0, 0.2)", "Chờ xác nhận"],
    1: ["#4E3636", "rgba(78, 54, 54, 0.2)", "Chờ lấy hàng"],
    2: ["#0079FF", "rgba(0, 121, 255, 0.2)", "Đang giao"],
    3: ["#008934", "rgba(0, 137, 52, 0.2)", "Đã giao"],
    4: ["#076170", "rgba(7, 97, 112, 0.2)", "Chuyển hoàn"],
    5: ["#333333", "rgba(151, 151, 151, 0.2)", "Huỷ giao hàng"],
    6: ["#EE0000", "rgba(238, 0, 0, 0.2)", "Lỗi giao hàng"],
  };
  try {
    return configs[status];
  } catch (error) {
    return configs[0];
  }
};

export const orderTypes = [
  { label: "Trực tiếp", value: "eat_in" },
  { label: "Giao đi", value: "ship" },
  { label: "Mang về", value: "take_away" },
];

export const getOrderLabel = (value) => {
  switch (value) {
    case "take_away":
      return "Mang về";
    case "ship":
      return "Giao đi";
    case "book_consultation":
      return "Liên hệ tư vấn";
    default:
      return "Trực tiếp";
  }
};

export const getLabelPair = (order_method) => {
  let orderFilterTypes = orderTypes?.filter(
    (orderType) => orderType?.value === order_method
  );
  return orderFilterTypes?.length > 0 ? orderFilterTypes[0]?.label : null;
};

export const getValuePair = (label) => {
  let orderFilterTypes = orderTypes?.filter(
    (orderType) => orderType?.label === label
  );
  return orderFilterTypes?.length > 0 ? orderFilterTypes[0]?.value : null;
};

export const checkIsView = (status) => {
  let isViewStatusList = ["Đã hủy", "Hoàn thành"];
  if (isViewStatusList?.indexOf(status) >= 0) {
    return true;
  }
  return false;
};

export const checkIsCancleOrder = (status) => {
  let isViewStatusList = ["Đã hủy"];
  return isViewStatusList?.indexOf(status) >= 0;
};

export const formatItem = (item) => {
  return {
    ...item,
    order_item: item?.productId,
    name: item?.productId,
    customer_id: item?.p_customer,
    customer_name: item?.p_customer_name,
    customer_phone: item?.p_customer_phone,
    note: item?.p_note || "",
    price: item?.price || item?.money,
    image: item?.image || item?.farm_item_image,
    farm_item: item?.farm_item || item?.item_id,
  };
};

export const getTotalPrice = (item) => {
  let total = item?.price;
  item?.toppings?.map((tpg) => {
    total += tpg?.price || 0;
    return tpg;
  });
  return total;
};

export const DynamicCheckBox = ({ type, value, onChange }) => {
  switch (type) {
    case 0:
      return <Radio checked={value} onClick={onChange} color="success" />;
    case 1:
      return <Checkbox checked={value} onClick={onChange} color="success" />;
    default:
      return null;
  }
};

// KIỂM TRA LÀ ĐƠN TẠI QUẦY
export const checkIsEatInOrder = (status, checkLabel = false) => {
  if (checkLabel) {
    let orderType = orderTypes.find((orderType) => orderType.label === status);
    return orderType?.label === getOrderLabel("eat_in");
  } else {
    return status === "eat_in";
  }
};

// KIỂM TRA LÀ ĐƠN GIAO ĐI
export const checkIsShipOrder = (status, checkCode = true) => {
  return status === "ship";
};
