import { Box } from "@mui/material";
import React from "react";

const LineSplitSection = ({ att }) => {
  return (
    <Box
      width="100%"
      sx={{
        height: 8,
        background: "#F2F2F2",
        ...att,
      }}
    />
  );
};

export default LineSplitSection;
